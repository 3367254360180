import { endpoints } from "./endpoints";
import { EnvironmentModel, MXURLS } from "./environment.modal";

export const MX: MXURLS = {
  notificationServiceBaseURL:
    "https://vb0t02fhgl.execute-api.us-west-2.amazonaws.com",
};
export const environment: EnvironmentModel = {
  production: false,
  endPoints: endpoints,
};
