import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { DateSelectionComponent } from './date-selection/date-selection.component';
import { CheckBoxComponent } from './check-box/check-box.component';
import { RangeSelectionComponent } from './range-selection/range-selection.component';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipsListComponent } from './chips-list/chips-list.component';
import { ChipsModule } from 'primeng/chips';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
    RadioButtonComponent,
    DateSelectionComponent,
    CheckBoxComponent,
    RangeSelectionComponent,
    InputComponent,
    ButtonComponent,
    ChipsListComponent
  ],
  imports: [
    SharedModule,
    FormsModule,
    CheckboxModule,
    ButtonModule,
    CalendarModule,
    ChipsModule
  ],
  exports: [
    RadioButtonComponent,
    CheckBoxComponent,
    ChipsListComponent
  ],
})
export class AtomModule { }
