import { MX } from "./environment";

export const endpoints = {
  notifications: MX.notificationServiceBaseURL + "/notification",
  comments: MX.notificationServiceBaseURL + "/comments",
  getCommentByNotificationId:
    MX.notificationServiceBaseURL + "/comments/{notification_id}",
  getCSRFToken: MX.notificationServiceBaseURL + "/csrf-token",
  filters: MX.notificationServiceBaseURL + "/filters",
  filtersCodesetLookup: MX.notificationServiceBaseURL + "/codeset-lookup",
  userSettings: MX.notificationServiceBaseURL + "/user-settings",
  downloadCSV: MX.notificationServiceBaseURL + "/notification/download-csv",
  getNotificationsHasUpdate:
    MX.notificationServiceBaseURL + "/notification/has/updates",
  updateNotificationReadUnread:
    MX.notificationServiceBaseURL + "/notification-read",
  logout: MX.notificationServiceBaseURL + "/users/logout",
};
