<p-calendar
  [selectionMode]="selectionMode"
  [showIcon]="showIcon"
  [placeholder]="placeholder"
  [dateFormat]="dateFormat"
  [rangeSeparator]="rangeSeparator"
  [(ngModel)]="value"
  (onSelect)="onDateSelect($event)"
>
</p-calendar>
