import { endpoints } from "./endpoints";
import { EnvironmentModel, MXURLS } from "./environment.modal";

//TODO: add development server address
export const MX: MXURLS = {
  notificationServiceBaseURL:
    "https://p0h9ysvv0d.execute-api.us-west-2.amazonaws.com",
};

export const environment: EnvironmentModel = {
  production: false,
  endPoints: endpoints,
};
