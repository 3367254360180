import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrl: './date-selection.component.scss',
})
export class DateSelectionComponent {
  @Input() selectionMode: 'single' | 'multiple' | 'range' = 'single'; 
  @Input() placeholder: string = 'Select a date';
  @Input() dateFormat: string = 'mm/dd/yy';
  @Input() showIcon: boolean = true; 
  @Input() rangeSeparator: string = '-';
  @Input() styleClass: string = '';

  @Input() value: Date | Date[] | null = null;

  @Output() dateChange: EventEmitter<Date | Date[]> = new EventEmitter<
    Date | Date[]
  >();

  onDateSelect(event: any) {
    this.dateChange.emit(event);
  }
}
