export const filterKeys = {
  EVENT_DATE: "event_date",
  GENDER: "gender",
  AGE: "age",
  PATIENT_CLASS: "patient_class",
  EVENT: "event",
  FACILITY: "facility",
  // Advanced:
  ADMIT_REASON: "admit_reason",
  DX_CODES: "dx_codes",
  DX_CODE: "dx_code",
  PCP_NAME: "pcp_name",
  DISCHARGE_DISPOSITION: "discharge_disposition",
};

export const operatorList = [
  { name: "Equal To", operation: "EQUAL" },
  { name: "Less Than", operation: "LESS_THAN" },
  { name: "Greater Than", operation: "GREATER_THAN" },
];

export enum OPERATORS {
  LESS_THAN_SIGN = "<",
  GREATER_THAN_SIGN = ">",
  EQUAL_TO_SIGN = "=",
  NOT_EQUAL_SIGN = "≠",
  BETWEEN = "BETWEEN",
  IN = "IN",
  EQUALS = "EQUALS",
  NOT_EQUAL = "NOT EQUAL",
  EQUAL_TO = "EQUAL TO",
  NOT_EQUAL_TO = "NOT EQUAL TO",
}

export const CODESET_LOOKUP_CONSTANTS = {
  ADMIT_REASON: "ADMIT_REASON",
  DISCHARGE_DISPOSITION: "DISCHARGE_DISPOSITION",
  DX_CODE: "DX_CODE",
  PCP_NAME: "PCP_NAME",
  FACILITY_NAME: "FACILITY_NAME",
};
