import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

interface Checkbox {
  label: string;
  value: any;
  icon: string;
  checked: boolean;
}

@Component({
  selector: "app-chekbox-icon",
  templateUrl: "./chekbox-icon.component.html",
  styleUrl: "./chekbox-icon.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChekboxIconComponent),
      multi: true,
    },
  ],
})
export class ChekboxIconComponent implements ControlValueAccessor{
  @Input() styleClass: string = "";
  @Input() pCheckboxStyleClass?: string = "";
  @Input() checkboxes: Checkbox[] = [];

  @Output() checkboxChange = new EventEmitter<
    { value: any; checked: boolean }[]
  >();

  onChange: any = () => {};
  onTouch: any = () => {};
  isDisabled: any = () => {};
  onTouched: any = () => {}
  writeValue(value: { value: any; checked: boolean }[]): void {
    if (value && value.length) {
      this.checkboxes = this.checkboxes.map((checkbox) => {
        const match = value.find((v) => v.value === checkbox.value);
        return match
          ? { ...checkbox, checked: match.checked }
          : { ...checkbox, checked: false };
      });
    } else {
      this.checkboxes = this.checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: false,
      }));
    }
  }

  // Registers the callback for when the form control's value changes
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Registers the callback for when the form control is touched
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Sets the disabled state for the component
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Triggered when a checkbox changes, emits selected values
  onCheckboxChange() {
    const selectedCheckboxes = this.checkboxes.filter((c) => c.checked);
    // this.onChange(selectedCheckboxes);  // Updates form control
    this.checkboxChange.emit(selectedCheckboxes);  // Emits changes to parent component

  }

  // onCheckboxChange() {
  //   const selectedCheckboxes = this.checkboxes.filter((c) => c.checked);
  //   this.checkboxChange.emit(selectedCheckboxes);
  // }
}
