import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
// import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class HttpErrorHandlerService {
  // constructor(private readonly messageSevice: MessageService) {}
  errorHandler: Subject<any> = new Subject();

  handleError(error: HttpErrorResponse) {
    // this.messageSevice.add({
    //   severity: "error",
    //   summary: "Error",
    //   detail: error.error.message,
    // });
    return error;
  }
}
