import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CommentService } from "../../../services/notification/comment.service";
import { notificationMock } from "../../../mock/notification.mock";
import { NotificationService } from "../../../services/notification/notification.service";
import { StateService } from "../../../services/state.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "mxn-notification-detail",
  templateUrl: "./notification-detail.component.html",
  styleUrl: "./notification-detail.component.scss",
})
export class NotificationDetailComponent implements OnInit {
  @Input() notification: any = {};
  @Output() statusBadgeChangeEvent = new EventEmitter<{
    notificationId: string;
    status: string;
    previousStatus: string;
  }>();

  @ViewChild("commentsSection") commentsSection!: ElementRef;
  constructor(
    private commentService: CommentService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private messageService: MessageService
  ) {}

  products: any;
  isSavingNewComment: boolean = false;
  editingCommentId: string = "";
  loading: boolean = false;
  isEmpty: boolean = false;

  statusBadgeIconMap = new Map([
    ["completed", "/assets/check.svg"],
    ["in-progress", "/assets/inprogress.svg"],
    ["not-started", "/assets/hovered.svg"],
  ]);

  ngOnInit(): void {
    this.getNotificationDetails();
    if (this.notification?.id) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
    this.stateService.setSelectedNotification(
      notificationMock(this.notification, this.stateService.getUser())
    );
    this.notification = notificationMock(
      this.notification,
      this.stateService.getUser()
    );
    this.products = [
      {
        code: "08-29-2024 8:05 AM",
        name: "P",
        category: "R03I10",
        quantity: "Essential (Primary) Hypertension",
        makras: "Marked as",
        action: "“Completed”",
        performedby: "June Ryan",
      },
      {
        code: "08-29-2024 8:05 AM",
        name: "K",
        category: "R03.0",
        quantity:
          "Elevated blood-pressure reading, without diagnosis of hypertensionElevated blood-pressur...",
      },
      {
        code: "08-29-2024 8:05 AM",
        name: "A",
        category: "R51.9",
        quantity: "Headache, unspecified",
        makras: "",
        action: "“Commented”",
        performedby: "June Ryan",
      },
      {
        code: "08-29-2024 8:05 AM",
        name: "A",
        category: "G44.201",
        quantity:
          "Tension-type headache, unspecified, intractableTension-type headache, unspecified, intrac...",
        makras: "",
        action: "“Commented”",
        performedby: "June Ryan",
      },
      {
        code: "08-29-2024 8:05 AM",
        name: "A",
        category: "R73. 0",
        quantity: "Abnormal glucose",
        makras: "Marked as",
        action: "“Read”",
        performedby: "June Ryan",
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["notification"] && !changes["notification"].isFirstChange()) {
      this.getNotificationDetails();
      if (this.notification?.id) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
  }

  getNotificationDetails() {
    if (this.notification?.id) {
      this.loading = true;
      this.notificationService
        .getNotificationById(this.notification?.id)
        .subscribe({
          next: (res: any) => {
            this.stateService.setSelectedNotification(res);
            this.notification = notificationMock(
              {
                ...this.stateService.getSelectedNotification(),
                id: this.notification?.id,
                status: res.status || this.notification?.status,
              },
              this.stateService.getUser()
            );
            this.loading = false;
            // Send postMessage to parent
            window.parent.postMessage(
              {
                type: "UUX-Patient-Context",
                patient: [
                  {
                    patientMrn: res.encounter.source_patient_identifier,
                    patientOrganization: res.encounter.source_uid,
                  },
                ],
              },
              "*"
            );
          },
          error: (err) => {
            this.isEmpty = true;
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.message || "Error while fetching notification",
            });
            this.loading = false;
          },
        });
    }
  }
  getAllCommentsCall() {
    if (this.notification?.id) {
      this.commentService
        .getCommentByNotificationId(this.notification?.id)
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.notification.comments = res.data;
            } else {
              this.notification.comments = [];
            }
          },
          error: (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.message || "Error while fetching comments",
            });
          },
        });
    }
  }
  getFormatedAuditLogData() {
    if (
      !this.notification?.auditLogs ||
      this.notification?.auditLogs.length === 0
    ) {
      return [];
    }
    return this.notification.auditLogs.map((log: any) => {
      return {
        dateTime: log.created_at,
        performedBy: `${log.user.first_name} ${log.user.last_name}`,
        action: log.type,
      };
    });
  }
  demographicsColumns: { [key: string]: string } = {
    dateTime: "Date - Time",
    performedBy: "Performed By",
    action: "Action",
  };
  diagnosisColumns: { [key: string]: string } = {
    code: "Date - Time",
    name: "Type",
    category: "Code",
    quantity: "Description",
  };
  demographicsHeaderClasses: string[] = [
    "w-15rem py-2 pr-2 pl-4",
    "w-15rem py-2 px-2",
    "px-2 py-2",
  ];
  demographicsBodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];
  diagnosisHeaderClasses: string[] = [
    "w-13rem py-2 pr-2 pl-3",
    "w-4rem py-2 px-2",
    "w-5rem py-2 px-2",
    "px-2 py-2",
  ];
  diagnosisBodyClasses: string[] = [
    "py-1 pr-2 pl-3 mxn-td-label",
    "py-1 px-2 mxn-td-label",
    "py-1 px-2 mxn-td-label",
    "py-1 px-2 mxn-td-info",
  ];
  laboratoryColumns: { [key: string]: string } = {
    test: "Test",
    observationDate: "Observation Date",
    lastResultDate: "Last Result Date",
    source: "Source",
    status: "Status",
  };

  laboratoryNestedColumns: { [key: string]: string } = {
    item: "Test Item",
    date: "Date",
    resultValue: "Result Value",
    unit: "Unit",
    referenceRange: "Reference Range",
    messageFlag: "Message Flag",
  };

  labHeaderClasses: string[] = [
    "w-13rem py-2 pr-2 pl-4",
    "w-13rem py-2 px-2",
    "w-13rem py-2 px-2",
    "w-13rem py-2 px-2",
    "px-2 py-2",
  ];

  labBodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];
  nestedLabHeaderClasses: string[] = [
    "w-13rem py-2 pr-2 pl-4",
    "w-13rem py-2 px-2",
    "w-13rem py-2 px-2",
    "w-13rem py-2 px-2",
    "px-2 py-2",
  ];

  nestedLabBodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];

  newComment = "";

  onEditComment(comnt: any) {
    const commentToEdit = this.notification.comments.find(
      (comment: { id: string }) => comment.id === comnt.id
    );
    if (commentToEdit) {
      commentToEdit.text = comnt.text;
      this.editingCommentId = "";
    }
  }

  toggleEditComment(comnt: any) {
    const commentToEdit = this.notification.comments.find(
      (comment: { id: string }) => comment.id === comnt.id
    );
    if (commentToEdit) {
      this.editingCommentId = commentToEdit.id;
    }
  }

  onDeleteComment(comment: any) {
    const commentIndex = this.notification.comments.findIndex(
      (c: { id: string }) => c.id === comment.id
    );

    if (commentIndex !== -1) {
      const confirmation = confirm(
        "Are you sure you want to delete this comment?"
      );
      if (confirmation) {
        this.notification.comments.splice(commentIndex, 1);
        this.commentService.deleteComment(comment?.id).subscribe({
          next: (res) => {
            this.getNotificationDetails();
          },
          error: (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.message || "Error while deleting comment",
            });
          },
        });
      }
    }
  }
  handleAddComment() {
    if (this.newComment.trim() === "") {
      alert("Comment cannot be empty");
      return;
    }
    this.isSavingNewComment = true;
    this.commentService
      .createComment({
        notification_id: this.notification.id,
        heading: "heading",
        text: this.newComment,
      })
      .subscribe({
        next: (res) => {
          this.getNotificationDetails();
          this.newComment = "";
          this.isSavingNewComment = false;
          this.editingCommentId = "";
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while adding comment",
          });
          this.isSavingNewComment = false;
        },
      });
  }

  scrollToComments() {
    if (this.commentsSection) {
      this.commentsSection.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  onStatusChange(notificationId: string, status: string) {
    let previousStatus = this.notification.statusBadge;
    this.notificationService
      .updateNotificationStatus(notificationId, {
        newStatus: status,
        currentStatus: previousStatus,
      })
      .subscribe({
        next: (res) => {
          this.notification.statusBadge = status;
          this.statusBadgeChangeEvent.emit({
            notificationId,
            status,
            previousStatus,
          });
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Status updated successfully",
          });
          this.getNotificationDetails();
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while updating status",
          });
          console.error("Error while subscribing:", err);
        },
      });
  }
}
