import { Component, Input, TemplateRef, Type } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "mxn-detail-panel",
  templateUrl: "./detail-panel.component.html",
  styleUrl: "./detail-panel.component.scss",
})
export class DetailPanelComponent {
  @Input() heading: string = "Demographics";
  @Input() contentTemplate!: TemplateRef<any>;
}
