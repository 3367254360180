import { Component } from '@angular/core';

@Component({
  selector: 'app-searchable-combo-box',
  templateUrl: './searchable-combo-box.component.html',
  styleUrl: './searchable-combo-box.component.scss'
})
export class SearchableComboBoxComponent {

}
