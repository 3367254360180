import { Component, Input } from "@angular/core";

interface DetailInfo {
  label: string;
  value: string;
}

@Component({
  selector: "mxn-detail-info",
  templateUrl: "./detail-info.component.html",
  styleUrls: ["./detail-info.component.scss"],
})
export class DetailInfoComponent {
  @Input() details: DetailInfo[] = [];
  @Input() columns: number = 2;

  get firstHalf() {
    return this.details?.slice(
      0,
      Math.ceil(this.details.length / this.columns)
    );
  }

  get secondHalf() {
    return this.details?.slice(Math.ceil(this.details.length / this.columns));
  }
}
