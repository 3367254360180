import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CommentService } from "../../../services/notification/comment.service";
import { IComment } from "../../../models/comment.model";
import { MessageService } from "primeng/api";

@Component({
  selector: "mxn-comment-card",
  templateUrl: "./comment-card.component.html",
  styleUrl: "./comment-card.component.scss",
})
export class CommentCardComponent {
  constructor(
    private messageService: MessageService,
    private commentService: CommentService
  ) {}
  @Input() notificationId: string = "";
  @Input() comment: IComment = {} as IComment;
  @Input() startedEditing: boolean = false;
  isDeletable: boolean = true;
  @Input() isEditable: boolean = true;

  @Output() onClickEditComment = new EventEmitter<any>();
  @Output() toggleEditComment = new EventEmitter<any>();
  @Output() onClickDeleteComment = new EventEmitter<any>();
  @Output() refreshNotificationDetail = new EventEmitter<any>();

  isEditing: boolean = false;
  commentValue: string = "";
  commentedBy: string = "";

  ngOnInit(): void {
    this.commentValue = this.comment?.text ?? "";
    this.commentedBy =
      this.comment?.user?.first_name && this.comment?.user?.last_name
        ? `${this.comment.user?.first_name} ${this.comment.user?.last_name}`
        : this.comment?.user?.email ?? "";
  }

  toggleEditView() {
    this.isEditing = true;
    this.toggleEditComment.emit({
      id: this.comment.id,
    });
  }

  onChangeComment(event: any) {
    this.commentValue = event.target.value;
  }
  onSave() {
    this.onClickEditComment.emit({
      id: this.comment.id,
      text: this.commentValue,
      heading: this.comment.heading,
      user: this.comment.user,
    });
    this.commentService
      .updateComment(this.comment.id, {
        text: this.commentValue,
        heading: this.comment.heading,
      })
      .subscribe({
        next: (res) => {
          this.refreshNotificationDetail.emit();
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while updating comment",
          });
          console.error(err);
        },
      });
    this.isEditing = false;
  }

  onDelete() {
    this.onClickDeleteComment.emit({
      id: this.comment.id,
    });
  }
}
