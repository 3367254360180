import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DropdownFilterEvent } from "primeng/dropdown";
import { debounceTime, Subject } from "rxjs";

@Component({
  selector: "app-combo-box",
  templateUrl: "./combo-box.component.html",
  styleUrls: ["./combo-box.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboBoxComponent),
      multi: true,
    },
  ],
})
export class ComboBoxComponent implements ControlValueAccessor {
  private searchSubject = new Subject<DropdownFilterEvent>();
  private readonly debounceTimeMs = 500;
  @Input() id: any = null;
  @Input() items: any[] = [];
  @Input() optionLabel: string = "name";
  @Input() placeholder: string = "Select an item";
  @Input() width: string = "200px";
  @Input() filter: boolean = false;
  @Input() showClear: boolean = false;
  @Input() editable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() dropdownIcon: string = "";
  @Input() styleClass: string = "";
  @Input() showSelected: boolean = true;

  @Output() selectionChange = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();
  @Output() onFilter = new EventEmitter<any>();
  selectedItem: any = null;

  // These are for ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  ngOnInit() {
    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue) => {
      this.performSearch(searchValue);
    });
  }
  
  ngOnDestroy() {
    this.searchSubject.complete();
  }

  // Called when the value in the form is updated
  writeValue(value: any): void {
    if (value) {
      this.selectedItem = value;
    } else {
      this.selectedItem = "";
    }
  }

  // Register the function that should be called when the control's value changes in the UI
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Register the function that should be called when the control receives a touch event
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // This is called when the disabled status changes
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectionChange() {
    this.selectionChange.emit(this.selectedItem);
    if (!this.showSelected) {
      setTimeout(() => {
        this.selectedItem = "";
      }, 50);
    }
  }

  handleClear() {
    this.onClear.emit();
    this.onChange(null);
  }

  handleFilter(event: DropdownFilterEvent) {
    this.searchSubject.next(event);
  }

  performSearch(event: DropdownFilterEvent) {
    this.onFilter.emit(event);
  }
}
