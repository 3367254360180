import { Injectable } from "@angular/core";
import { INotification } from "../models/notification.model";
import { IFilter } from "../models/filter.model";
import { IUser } from "../models/user.model";
import { SavedFilter } from "./filter.service";

export interface RequestHeaderFields {
  "uux-session-id": string;
  pool_id: string;
  region: string;
  organization: string;
}

@Injectable({
  providedIn: "root",
})
export class StateService {
  private user: IUser = {} as IUser;
  private notifications: INotification[] = [];
  private selectedNotification: INotification = {} as INotification;
  private filters: IFilter[] = [];
  private accessToken: string = "";
  private csrfToken: string = "";
  private savedFilters: SavedFilter[] = [];
  private notificationCountByStatus: any = {};

  private requestHeaderFields: RequestHeaderFields = {
    "uux-session-id": "",
    pool_id: "",
    region: "",
    organization: "",
  };

  constructor() {}

  setMXRequestHeaderFields(requestHeaderFields: any | null): void {
    this.requestHeaderFields =
      { ...this.requestHeaderFields, ...requestHeaderFields } ??
      ({} as RequestHeaderFields);
  }
  getMXRequestHeaderFields(): RequestHeaderFields {
    return this.requestHeaderFields;
  }

  // Token
  setCSRFToken(csrfToken: string): void {
    this.csrfToken = csrfToken;
  }

  getCSRFToken(): string {
    return this.csrfToken;
  }

  // Access Token
  setAccessToken(accessToken: string): void {
    this.accessToken = `Bearer ${accessToken}`;
  }

  getAccessToken(): string {
    return this.accessToken;
  }
  // User
  setUser(user: IUser | null): void {
    this.user = user ?? ({} as IUser);
  }

  getUser(): IUser {
    return this.user;
  }

  setNotificationCountByStatus(data: any) {
    this.notificationCountByStatus = data;
  }
  getNotificationCountByStatus() {
    return this.notificationCountByStatus;
  }
  // Notifications
  getNotifications(): INotification[] {
    return this.notifications;
  }

  setNotifications(notifications: INotification[]) {
    this.notifications = notifications;
  }

  addNotification(notification: INotification) {
    this.notifications.push(notification);
  }

  updateNotification(updatedNotification: INotification) {
    const index = this.notifications.findIndex(
      (n) => n.id === updatedNotification.id
    );
    if (index > -1) {
      this.notifications[index] = updatedNotification;
    }
  }

  setSelectedNotification(notification: any) {
    this.selectedNotification = notification;
  }
  getSelectedNotification() {
    return this.selectedNotification;
  }
  // Filters
  getFilters(): IFilter[] {
    return this.filters;
  }

  setFilters(filters: IFilter[]) {
    this.filters = filters;
  }

  addFilter(filter: IFilter) {
    this.filters.push(filter);
  }

  updateFilter(updatedFilter: IFilter) {
    const index = this.filters.findIndex((n) => n.name === updatedFilter.name);
    if (index > -1) {
      this.filters[index] = updatedFilter;
    }
  }

  setSavedFilters(data: SavedFilter[] | any) {
    this.savedFilters = data;
  }
  getSavedFilters() {
    return this.savedFilters;
  }

  logout() {
    this.setUser(null);
    this.setAccessToken("");
    this.setMXRequestHeaderFields(null);
    this.setCSRFToken("");
    window.parent.postMessage("UUX-Logout", "*");
  }
}
