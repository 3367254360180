// activity.service.ts
import { Injectable, NgZone } from "@angular/core";
import { fromEvent, Observable, merge, timer, Subject } from "rxjs";
import { mapTo, startWith, switchMap, takeUntil, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  private inactivityTimeout$ = new Subject<void>();
  private activityDetected$ = new Subject<boolean>();

  constructor(private ngZone: NgZone) {}

  monitorInactivity(timeoutMs: number): Observable<boolean> {
    // Use Angular's NgZone to listen for events outside Angular's zone
    return this.ngZone.runOutsideAngular(() => {
      // Define events to monitor
      const activityEvents$ = merge(
        fromEvent(document, "mousemove"),
        fromEvent(document, "keydown"),
        fromEvent(document, "click")
      );

      // Reset inactivity timer on any activity
      return activityEvents$.pipe(
        tap(() => this.activityDetected$.next(true)), // Emit true whenever there's activity
        switchMap(() => timer(timeoutMs).pipe(mapTo(false))),
        startWith(true), // Start with an active state
        takeUntil(this.inactivityTimeout$)
      );
    });
  }
  onActivity(): Observable<boolean> {
    return this.activityDetected$.asObservable();
  }

  // Call this method to stop monitoring inactivity
  stopMonitoring() {
    this.inactivityTimeout$.next();
  }
}
