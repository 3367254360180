<div *ngIf="loading" class="flex justify-content-center align-items-center mt-5">
  <p-progressSpinner></p-progressSpinner>
</div>
<div *ngIf="filters.length!==0">
<div *ngFor="let filter of filters;" [ngClass]="styleClass" [id]="filter.id" class="max-saveflte-panel px-4 py-3 border-gray">
  <div class="max-savedFlte-header flex gap-2 justify-content-between align-items-center">
    <div class="flex align-items-center gap-2">
        <div *ngIf="!filter.isEditing" class="max-savedFlte-title">
          <div class="flex gap-1 align-content-center">
            <span >{{ filter.name }}</span>
            <img src="/assets/info-icon.svg" alt="info" [pTooltip]="getToolTip(filter)" tooltipPosition="right" positionStyle="fixed" tooltipEvent="hover"/>
            <!-- <div class="mxn-overlay mxn-status-tooltip">
              <img src="/assets/info-icon.svg" alt="info"/>
              <div class="mxn-overlay-ddm">
                <div class="mxn-overlay-items w-8rem">
                  {{getToolTip(filter)}}
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <input *ngIf="filter.isEditing" type="text" [value]="filter.name" [(ngModel)]="filter.name" >
        <input *ngIf="!filter.isEditing" type="button" value="Apply" (click)="onApplyFilter(filter)" class="max-filter-actBtn" />
    </div>
    <div>
      <div class="justify-content-between gap-2 mxn-saved-fltrEdit pr-4" *ngIf="filter.isEditable">
        <button *ngIf="!filter.isEditing" class="bg-transparent border-none cursor-pointer" (click)="onEdit(filter)"><img src="/assets/edit-icon.svg" alt="edit"></button>
        <button *ngIf="filter.isEditing" class="bg-transparent border-none cursor-pointer" (click)="onSave(filter)"><img src="/assets/saveFltr.svg" alt="save"></button>
        <button class="bg-transparent border-none cursor-pointer" (click)="onDelete(filter)"><img src="/assets/trash.svg" alt="trash"></button>
    </div>
    </div>
  </div>
  <app-chips-list [chips]="filter.filterValues" [removable]="edit" (removedChip)="handleRemovedChip($event, filter)"></app-chips-list>
</div>
</div>
<p-dialog [modal]="true" [(visible)]="visible" styleClass="max-confirmation-dialog">
  <div class="flex gap-3 px-4">
    <div class="mxn-popup-icon">
      <div class="mxn-warning">
        <img src="assets/ph_warning-light.svg" alt="warning-light">
      </div>
    </div>
    <div class="flex-1">
      <h3 class="m-0 mb-2">Alert</h3>
      <p class="m-0">Are you sure you want to delete <strong>{{toBeDeleted?.name??''}}</strong> filter?</p>
      <p class="m-0">This action cannot be undone</p>
    </div>
  </div>
  <div class="flex justify-content-end gap-2 max-dialog-footer">
    <p-button label="Cancel" severity="secondary" (onClick)="handleCancelDelete()" class="max-dialog-cancel-btn"/>
    <p-button label="Confirm" (onClick)="visible = false" (onClick)="handleConfirmDelete()" class="max-dialog-Confirm-btn"/>
</div>
</p-dialog>
