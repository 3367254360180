import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "mxn-pagination",
  templateUrl: "./pagination.component.html",
  styleUrl: "./pagination.component.scss",
})
export class PaginationComponent {
  @Input() totalItems: number = 100;
  @Input() rowsPerPage: number = 10;
  @Input() currentPage: number = 1;
  @Input() rowsOptions: any[] = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "30", value: 30 },
  ];

  @Output() pageChange: EventEmitter<any> = new EventEmitter();

  totalPages: number = 0;
  paginationArray: (number | string)[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateTotalPages();
    this.buildPaginationArray();
  }

  // Calculate total pages
  updateTotalPages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.rowsPerPage);
  }

  // Handle page change
  changePage(page: number | string): void {
    if (page === "..." || (page as number) > this.totalPages) {
      return;
    }
    if ((page as number) >= 1 && (page as number) <= this.totalPages) {
      this.currentPage = page as number;
      this.buildPaginationArray();
      this.emitChange();
      this.cdr.detectChanges();
    }
  }

  // Handle rows per page change
  changeRowsPerPage(event: any): void {
    this.rowsPerPage = event.value.value;
    this.currentPage = 1;
    this.updateTotalPages();
    this.buildPaginationArray();
    this.emitChange();
    this.cdr.detectChanges();
  }

  emitChange(): void {
    this.pageChange.emit({
      currentPage: this.currentPage,
      rowsPerPage: this.rowsPerPage,
      totalItems: this.totalItems,
      totalPages: this.totalPages,
    });
  }

  buildPaginationArray(): void {
    const maxVisibleButtons = 2;
    this.paginationArray = [];

    if (this.totalPages <= 5) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.paginationArray.push(i);
      }
    } else {
      this.paginationArray.push(1);

      if (this.currentPage > maxVisibleButtons + 1) {
        this.paginationArray.push("...");
      }

      let startPage = Math.max(2, this.currentPage - 1);
      let endPage = Math.min(this.totalPages - 1, this.currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        this.paginationArray.push(i);
      }

      if (this.currentPage < this.totalPages - maxVisibleButtons) {
        this.paginationArray.push("...");
      }

      this.paginationArray.push(this.totalPages);
    }
    this.cdr.detectChanges();
  }

  isActive(page: number | string): boolean {
    return this.currentPage === (page as number);
  }
}
