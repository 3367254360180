import { Component } from '@angular/core';

@Component({
  selector: 'app-range-selection',
  templateUrl: './range-selection.component.html',
  styleUrl: './range-selection.component.scss'
})
export class RangeSelectionComponent {

}
