<div class="flex flex-wrap w-full">
  <div
  *ngFor="let checkbox of checkboxes"
  [ngClass]="styleClass"
  class="col-6 px-0 py-2"
>
  <p-checkbox
    [(ngModel)]="checkbox.checked"
    [binary]="true"
    (onChange)="onCheckboxChange()"
    [label]="checkbox.label"
    [ngClass]="pCheckboxStyleClass"
    class=""
  ></p-checkbox>
</div>
</div>
