import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceClientService } from "../service-client.service";
import { endpoints } from "../../../environments/endpoints";
import { IResponse } from "../../models/response.model"; // Assuming Response is a predefined generic model
import { IComment, CommentPayload } from "../../models/comment.model"; // Assuming Comment is a predefined model
import { IRequest } from "../../models/request.modal";
import { HttpErrorResponse } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class CommentService {
  constructor(private serviceClientService: ServiceClientService) {}

  // Create a new comment
  createComment(
    request: any
  ): Observable<IResponse<IComment> | HttpErrorResponse> {
    return this.serviceClientService.post<
      IRequest<CommentPayload>,
      IResponse<IComment> | HttpErrorResponse
    >(endpoints.comments, request);
  }
  // Retrieve all comments
  getCommentByNotificationId(notificationId: string): Observable<any> {
    return this.serviceClientService.get<
      IResponse<IComment[]> | HttpErrorResponse
    >(`${endpoints.comments}/${notificationId}`);
  }

  // Update a comment
  updateComment(
    commentId: string,
    request: any
  ): Observable<IResponse<IComment> | HttpErrorResponse> {
    return this.serviceClientService.put<
      IRequest<CommentPayload>,
      IResponse<IComment> | HttpErrorResponse
    >(`${endpoints.comments}/${commentId}`, request) as Observable<
      IResponse<IComment> | HttpErrorResponse
    >;
  }

  // Delete a comment
  deleteComment(
    commentId: string
  ): Observable<IResponse<any> | HttpErrorResponse> {
    return this.serviceClientService.delete<IResponse<any> | HttpErrorResponse>(
      `${endpoints.comments}/${commentId}`
    );
  }
}
