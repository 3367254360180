<p-sidebar
  [(visible)]="visible"
  [baseZIndex]="10000"
  position="right"
  styleClass="p-sidebar-md mxn-filter-sidebar"
  (onHide)="closeSidebar()"
  id="max-filterSidebar"
>
  <ng-template pTemplate="header">
    <span class="font-semibold text-xl">Filters</span>
  </ng-template>
  <p-tabView styleClass="max-filter-tabs" [(activeIndex)]="activeTabIndex">
    <p-tabPanel header="Filters">
      <div class="filter-section">
        <app-filter-form #filterFormComponent [visible]="visible" [editedFilter]="editedFilter" [appliedFilter]="appliedFilter" (save)="handleSaveFilterFromForm($event)" (cancel)="closeSidebar()" (closeSidebarEvent)="closeSidebar()" (resetEvent)="handleResetEvent()" (applyFilters)="handleApplyFilters($event)" (disableApplyORSaveEvent)="handleDisableApplyORSave($event)"> </app-filter-form>
      </div>
    </p-tabPanel>

    <!-- Saved Filters Tab -->
    <p-tabPanel header="Saved Filters">
      <div class="saved-filters-section">
        <app-saved-filter-list
          #savedFilterListComponent
          [sidebarVisible]="visible"
          [loading]="loadingSavedFilters"
          [filters]="savedFilters"
          (applyFilter)="handleApplyFilterFromSave($event)"
          (saveFilter)="handleSaveFilterFromSave($event)"
          (editFilter)="handleEditFilter($event)"
          (deleteFilter)="handleDeleteFilter($event)"
        >
        </app-saved-filter-list>
      </div>
    </p-tabPanel>
  </p-tabView>
  <ng-template pTemplate="footer">
    <div class="flex gap-2">
      <div class="col-6 pl-0">
        <p-button label="Cancel" styleClass="max-cancel-btn" (onClick)="handleCancel()"/>
      </div>
      <div *ngIf="activeTabIndex==0" class="col-6 pr-0">
        <div class="flex max-fltrBtn-wrapper">
          <p-button label="Apply Filters" [disabled]="disableApplyORSave" class="w-full flex-1" styleClass="max-FltApply-btn" (onClick)="handleApply()"/>
          <div class="max-FltSave-btnicon">
            <em class="pi pi-angle-down"></em>
          </div>
          <div class="max-FltSave-tooltip">
            <p-button label="Save Filters" [disabled]="disableApplyORSave" styleClass="max-FltSave-btn" (click)="handleSave()"/>
          </div>
        </div>
      </div>
      
    </div>
</ng-template>
</p-sidebar>
