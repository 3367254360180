import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FilterService } from "../services/filter.service";
import { SavedFilter, Filter } from "../models/filter.model";
import { FilterFormComponent } from "../filter-form/filter-form.component";
import { StateService } from "../services/state.service";
import { take } from "rxjs";
import { SavedFilterListComponent } from "../ui/molecule/saved-filter-list/saved-filter-list.component";
import { MessageService } from "primeng/api";
import { IUser } from "../models/user.model";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrl: "./filters.component.scss",
})
export class FiltersComponent {
  @Input() visible: boolean = false;
  @Output() close = new EventEmitter<void>();
  @Output() applied = new EventEmitter<number>();
  @Output() applyFilters = new EventEmitter<Filter[]>();

  @ViewChild(FilterFormComponent) filterFormComponent!: FilterFormComponent;
  @ViewChild(SavedFilterListComponent)
  savedFilterListComponent!: SavedFilterListComponent;

  appliedFilter: SavedFilter | null = null;
  savedFilters: SavedFilter[] = [];
  editedFilter: SavedFilter | null = null;
  filterToBeSaved: SavedFilter | null = null;
  activeTabIndex: number = 0;
  disableApplyORSave: boolean = true;
  currentUser: IUser | null = null;

  loadingSavedFilters: boolean = true;

  constructor(
    public filterService: FilterService,
    public stateService: StateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.currentUser = this.stateService.getUser();
      this.filterService
        .getAllSavedFilters()
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.savedFilters = data;
            this.loadingSavedFilters = false;
          },
          error: (err) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: err.message || "Error while fetching saved filters",
            });
          },
        });
    }, 5000);
  }

  clearAppliedFilters() {
    // this.filterFormComponent.formReset();
    this.appliedFilter = null;
    // this.editedFilter = null;
    this.applied.emit(0);
  }

  handleDisableApplyORSave(bool: boolean) {
    this.disableApplyORSave = bool;
  }

  handleApply() {
    this.filterFormComponent.clickApplyFilter();
  }

  handleApplyFilter(filters: Filter[]) {
    this.appliedFilter = { filterValues: filters };
    this.applied.emit(filters.length);
  }

  handleApplyFilterFromSave(savedFilter: SavedFilter) {
    this.appliedFilter = savedFilter;
    this.activeTabIndex = 0;
    this.applied.emit(this.appliedFilter.filterValues.length);
    this.closeSidebar();
  }

  handleEditFilter(editedFilter: SavedFilter) {
    this.editedFilter = editedFilter;
    this.activeTabIndex = 0;
  }

  handleSave() {
    this.filterFormComponent.clickSaveFilter();
  }

  handleAppliedFilterRemoveSingle(chip: { key: string; value: string }) {
    if (this.editedFilter)
      this.editedFilter.filterValues = this.editedFilter.filterValues.filter(
        (af) => af.key !== chip.key
      );
  }

  getAllSavedFilters() {
    this.filterService.getAllSavedFilters().subscribe({
      next: (data) => {
        this.savedFilters = data;
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: err.message || "Error while fetching saved filters",
        });
      },
    });
  }

  handleSaveFilterFromSave(filter: SavedFilter | any) {
    this.editedFilter = null;
    this.activeTabIndex = 1;
    this.filterService
      .saveFilter(filter)
      .pipe(take(1))
      .subscribe({
        next: () => this.getAllSavedFilters(),
        error: (error) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: error.message || "Error while saving filters",
          });
          this.getAllSavedFilters();
        },
      });
  }

  handleDeleteFilter(filter: SavedFilter) {
    if (filter.id)
      this.filterService
        .delete(filter)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.filterService.getAllSavedFilters().subscribe({
              next: (data) => {
                this.savedFilters = data;
              },
              error: (err) => {
                this.messageService.add({
                  severity: "error",
                  summary: "Error",
                  detail: err.message || "Error while fetching saved filters",
                });
              },
            });
          },
          error: (error) => {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: error.message || "Error while fetching saved filters",
            });
          },
        });
    else this.savedFilters = this.savedFilters.filter((f) => f != filter);
  }

  handleSaveFilterFromForm(filter: SavedFilter) {
    if (filter.filterValues.length > 0) {
      this.activeTabIndex = 1;
    }
    if (this.editedFilter && filter.id && this.savedFilters) {
      this.savedFilters = this.savedFilters?.map((f) => {
        if (f.id == filter.id) {
          return { ...filter, isEditing: true };
        }
        return { ...f };
      });
    } else {
      let editedFilter = this.savedFilters.filter((sf) => sf.isEditing == true);
      if (!editedFilter.length) {
        this.savedFilters = [
          ...(this.savedFilters ?? []),
          { filterValues: filter.filterValues, isEditing: true },
        ];
      } else {
        this.savedFilters[this.savedFilters.length - 1].filterValues =
          filter.filterValues;
      }
    }
  }

  handleCancel() {
    this.filterFormComponent.clickCancel();
    this.closeSidebar();
    this.filterService
      .getAllSavedFilters()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.savedFilters = data;
          this.loadingSavedFilters = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while fetching saved filters",
          });
        },
      });
  }

  closeSidebar(): void {
    this.savedFilterListComponent.close();
    this.activeTabIndex = 0;
    // this.editedFilter = null
    this.close.emit();
  }

  handleResetEvent() {
    this.savedFilterListComponent.close();
    this.appliedFilter = null;
    this.editedFilter = null;
    this.savedFilters = this.savedFilters.filter((sf) => sf.id);
  }

  handleApplyFilters(filters: Filter[]) {
    this.applyFilters.emit(filters);
  }
}
