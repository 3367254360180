import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  DataItem,
  Filter,
  FilterCodeset,
  FilterCodesetResponse,
  FilterService,
  SavedFilter,
} from "../services/filter.service";
import {
  filterKeys,
  operatorList,
  OPERATORS,
  CODESET_LOOKUP_CONSTANTS,
} from "../constants/filters.constants";
import { Options } from "@angular-slider/ngx-slider";
import { DatePipe } from "@angular/common";
import { InputNumberInputEvent } from "primeng/inputnumber";
import { map } from "rxjs";
import { DropdownFilterEvent } from "primeng/dropdown";
import { ComboBox, CheckBox, AdvancedFilter } from "../models/filter.model";

enum HEADING {
  SELECTED = "selected",
  APPLIED = "applied",
  EDITED = "edited",
}

enum RADIO {
  NONE = "none",
  SINGLE = "single",
  RANGE = "range",
  INTERVAL = "interval",
}

@Component({
  selector: "app-filter-form",
  templateUrl: "./filter-form.component.html",
  styleUrl: "./filter-form.component.scss",
})
export class FilterFormComponent {
  @Input() visible: boolean = false;
  @Input() editedFilter: SavedFilter | null = null;
  @Input() appliedFilter: SavedFilter | null = null;
  @Output() save = new EventEmitter<SavedFilter>();
  @Output() apply = new EventEmitter<Filter[]>();
  @Output() closeSidebarEvent = new EventEmitter<void>();
  @Output() resetEvent = new EventEmitter<void>();
  @Output() applyFilters = new EventEmitter<Filter[]>();
  @Output() disableApplyORSaveEvent = new EventEmitter<boolean>();

  _filter: SavedFilter | null = null;
  heading: string = HEADING.SELECTED;
  RADIO = RADIO;
  filterActiveIndexes: number[] | null = [0, 6];
  ingredient: any;
  selectedtimeinterval: any;
  value: any;
  first: boolean = true;
  timeinterval: any[] | undefined;
  selectedDates: Date[] = [];
  showInlineCalander: boolean = false;
  showMultiEventDate: boolean = false;
  eventDateRangePlaceHolder: string = "Start date - End date";
  selectedRange: string = "custom";

  ageRequiredStar = false;
  eventDateRequiredStar = false;
  advancedFilterRequiredStar = false;

  constructor(
    public filterService: FilterService,
    private datePipe: DatePipe
  ) {}

  genderFilterList = [
    { label: "Male", value: "male", icon: "pi pi-check", checked: false },
    { label: "Female", value: "female", icon: "pi pi-times", checked: false },
    { label: "Other", value: "other", icon: "pi pi-star", checked: false },
  ];

  filterForm: FormGroup = new FormGroup({
    eventDate: new FormControl<string>(""),
    eventDateRange: new FormControl<any>(null),
    radioEventDate: new FormControl<string>(this.RADIO.NONE),
    eventDateTimeInterval: new FormControl<string | null>(null),
    gender: new FormControl<{ value: any; checked: boolean }[]>([]),
    isAgeRange: new FormControl<boolean | null>(null),
    ageRange: new FormControl(null),
    age: new FormControl(null),
    ageOperation: new FormControl(null),
    patientClass: new FormControl(null),
    event: new FormControl(null),
    facility: new FormControl<ComboBox[]>([]),
  });

  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 100,
    disabled: true,
    draggableRange: false,
  };

  ngOnInit() {
    this.disableinitialFields();
    setTimeout(() => {
      this.mapCodesetToFields({
        page: 1,
        limit: 30,
        type: CODESET_LOOKUP_CONSTANTS.FACILITY_NAME,
        search: "",
      });
    }, 5000);
    this.age?.setValidators([Validators.min(1), Validators.max(200)]);
    this.eventDateTimeInterval?.setValidators([Validators.required]);
    this.eventDate?.setValidators([Validators.required]);
    this.eventDateRange?.setValidators([Validators.required]);

    this.filterForm.valueChanges.subscribe((data) => {
      let filter = this.mapFormToFilter();
      this._filter = { ...this._filter, filterValues: filter };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.visible && this.heading !== HEADING.APPLIED) {
      this.formReset();
      this.heading = HEADING.SELECTED;
    }
    if (changes["editedFilter"]) {
      const previousValue = changes["editedFilter"].previousValue;
      const currentValue = changes["editedFilter"].currentValue;
      const firstChange = changes["editedFilter"].firstChange;
      if (currentValue && currentValue !== previousValue && !firstChange) {
        this.formReset();
        this.initializeFilterForm(currentValue);
        this._filter = currentValue;
        this.heading = HEADING.EDITED;
      }
      if (currentValue == null) {
        this.formReset();
        this._filter = null;
        this.heading = HEADING.SELECTED;
      }
    }
    if (changes["appliedFilter"]) {
      const previousValue = changes["appliedFilter"].previousValue;
      const currentValue = changes["appliedFilter"].currentValue;
      const firstChange = changes["appliedFilter"].firstChange;
      if (currentValue && currentValue !== previousValue && !firstChange) {
        this.formReset();
        this.initializeFilterForm(currentValue);
        this._filter = currentValue;
        this.heading = HEADING.APPLIED;
        this.applyFilters.emit(this._filter?.filterValues);
      }
      if (currentValue == null) {
        this.formReset();
        this._filter = null;
        this.heading = HEADING.SELECTED;
      }
    }
  }

  getCodesetLookup(query: FilterCodeset) {
    return this.filterService.getCodeSet(query);
  }

  eventDateTimeIntervalList = [
    { name: "Last 1 hour", value: "Last 1 hour" },
    { name: "Last 4 hour", value: "Last 4 hour" },
    { name: "Last 6 hour", value: "Last 6 hour" },
    { name: "Last 24 hour", value: "Last 24 hour" },
  ];

  patientClassList = [
    { label: "Emergency", value: "emergency", checked: false },
    { label: "Outpatient", value: "outpatient", checked: false },
    { label: "Inpatient", value: "inpatient", checked: false },
    { label: "COVID", value: "covid", checked: false },
  ];

  eventList = [
    { label: "Admit", value: "Admit", checked: false },
    {
      label: "Transfer Inpatient to Outpatient",
      value: "Transfer Inpatient to Outpatient",
      checked: false,
    },
    { label: "Discharge", value: "Discharge", checked: false },
    { label: "Cancel Admit", value: "Cancel Admit", checked: false },
    { label: "Registration", value: "Registration", checked: false },
    { label: "Cancel Transfer", value: "Cancel Transfer", checked: false },
    {
      label: "Transfer Outpatient to Inpatient",
      value: "Transfer Outpatient to Inpatient",
      checked: false,
    },
    { label: "Cancel Discharge", value: "Cancel Discharge", checked: false },
  ];

  operatorList = operatorList;

  signOperator = [
    { name: "Equal to", operation: OPERATORS.EQUAL_TO },
    { name: "Not Equal to", operation: OPERATORS.NOT_EQUAL_TO },
  ];

  facilityList: ComboBox[] = [];

  advanceItems = [
    { name: "Admit Reason", value: CODESET_LOOKUP_CONSTANTS.ADMIT_REASON },
    { name: "DX Codes", value: CODESET_LOOKUP_CONSTANTS.DX_CODE },
    { name: "PCP Name", value: CODESET_LOOKUP_CONSTANTS.PCP_NAME },
    {
      name: "Discharge Disposition",
      value: CODESET_LOOKUP_CONSTANTS.DISCHARGE_DISPOSITION,
    },
  ];

  advanceItemsOperatorList = [
    { name: "Equals", operation: OPERATORS.EQUALS },
    { name: "Not Equal", operation: OPERATORS.NOT_EQUAL },
  ];

  get eventDate() {
    return this.filterForm.get("eventDate");
  }
  get eventDateRange() {
    return this.filterForm.get("eventDateRange");
  }
  get radioEventDate() {
    return this.filterForm.get("radioEventDate");
  }
  get eventDateTimeInterval() {
    return this.filterForm.get("eventDateTimeInterval");
  }
  get gender() {
    return this.filterForm.get("gender");
  }
  get isAgeRange() {
    return this.filterForm.get("isAgeRange");
  }
  get ageRange() {
    return this.filterForm.get("ageRange");
  }
  get age() {
    return this.filterForm.get("age");
  }
  get ageOperation() {
    return this.filterForm.get("ageOperation");
  }
  get patientClass() {
    return this.filterForm.get("patientClass");
  }
  get event() {
    return this.filterForm.get("event");
  }
  get facility() {
    return this.filterForm.get("facility");
  }

  invalid(control: AbstractControl | null) {
    return control?.touched && !control?.value;
  }

  openTabs(indexes: number[]) {
    this.filterActiveIndexes = indexes;
  }

  closeTabs() {
    this.filterActiveIndexes = [];
  }

  disableinitialFields() {
    this.eventDate?.disable();
    this.eventDateTimeInterval?.disable();
    this.eventDateRange?.disable();
    this.age?.disable();
    this.ageRange?.disable();
    this.ageOperation?.disable();
  }

  toggleradioEventDate() {
    this.filterForm?.patchValue({
      radioEventDate: !this.radioEventDate?.value,
    });
  }

  handleEventDateTimeIntervalChange(event: ComboBox) {
    this.filterForm.patchValue({
      eventDateTimeInterval: event.value,
    });
  }

  handleEventDateToggle(value: string) {
    this.showInlineCalander = false;
    switch (value) {
      case this.RADIO.NONE:
        this.eventDateTimeInterval?.markAsUntouched();
        this.eventDate?.markAsUntouched();
        this.eventDateRange?.markAsUntouched();
        this.eventDateTimeInterval?.disable();
        this.eventDate?.disable();
        this.eventDateRange?.disable();
        this.eventDateRequiredStar = false;
        this.filterForm.patchValue({
          eventDateTimeInterval: null,
          eventDate: null,
          eventDateRange: null,
        });
        break;
      case this.RADIO.INTERVAL:
        this.eventDateTimeInterval?.markAsTouched();
        this.eventDate?.markAsUntouched();
        this.eventDateRange?.markAsUntouched();
        this.eventDateRequiredStar = true;
        this.eventDateTimeInterval?.enable();
        this.eventDate?.disable();
        this.eventDateRange?.disable();
        this.filterForm.patchValue({
          eventDate: null,
          eventDateRange: null,
        });
        break;
      case this.RADIO.SINGLE:
        this.eventDate?.markAsTouched();
        this.eventDateTimeInterval?.markAsUntouched();
        this.eventDateRange?.markAsUntouched();
        this.eventDateRequiredStar = true;
        this.eventDateTimeInterval?.disable();
        this.eventDate?.enable();
        this.eventDateRange?.disable();
        this.filterForm.patchValue({
          eventDateTimeInterval: null,
          eventDateRange: null,
        });
        break;
      case this.RADIO.RANGE:
        this.eventDateRange?.markAsTouched();
        this.eventDateTimeInterval?.markAsUntouched();
        this.eventDate?.markAsUntouched();
        this.eventDateRequiredStar = true;
        this.eventDateTimeInterval?.disable();
        this.eventDate?.disable();
        this.eventDateRange?.enable();
        this.filterForm.patchValue({
          eventDateTimeInterval: null,
          eventDate: null,
        });
        break;
    }
  }

  handleCalanderFocus() {
    this.showInlineCalander = !this.showInlineCalander;
  }

  closeInlineCalander() {
    if (this.eventDateRange?.value[0] && this.eventDateRange?.value[1])
      this.showInlineCalander = false;
  }

  setLastWeek() {
    this.selectedRange = "week";
    const today = new Date();
    const lastWeekStart = new Date(today);
    lastWeekStart.setDate(today.getDate() - 7); // Start date is 7 days ago
    this.selectedDates = [lastWeekStart, today];
    this.filterForm.patchValue({
      eventDateRange: [lastWeekStart, today],
    });
    this.closeInlineCalander();
  }

  setLastMonth() {
    this.selectedRange = "month";
    const today = new Date();
    const lastMonthStart = new Date(today);
    lastMonthStart.setDate(today.getDate() - 30); // Start date is 30 days ago
    this.selectedDates = [lastMonthStart, today];
    this.filterForm.patchValue({
      eventDateRange: [lastMonthStart, today],
    });
    this.closeInlineCalander();
  }

  setCustomRange() {
    this.selectedRange = "custom";
    this.filterForm.patchValue({
      eventDateRange: [],
    });
  }

  handleAgeInput(event: InputNumberInputEvent) {
    if (event.value == 0) {
      this.filterForm.patchValue({
        age: null,
      });
    }
  }

  handleGenderFilterChange(selectedCheckboxes: CheckBox[]) {
    this.filterForm.patchValue({
      gender: selectedCheckboxes,
    });
  }

  handleAgeToggle() {
    if (this.isAgeRange?.value == null) {
      this.age?.disable();
      this.ageOperation?.disable();
      this.ageRange?.disable();
      this.options = { ...this.options, disabled: true, draggableRange: false };
      this.filterForm.patchValue({
        isAgeRange: null,
        ageRange: null,
        age: null,
        ageOperation: null,
      });
      this.ageRange?.setValue([0, 19]);
    } else if (this.isAgeRange?.value) {
      this.ageRange?.enable();
      this.age?.disable();
      this.ageOperation?.disable();
      this.options = { ...this.options, disabled: false, draggableRange: true };
      this.age?.setErrors(null);
    } else {
      if (this.ageOperation?.value == null) {
        this.handleAgeOperationChange({
          name: "Equal To",
          operation: "EQUAL",
        });
      }
      if (this.age?.value == null) {
        this.age?.addValidators([Validators.required]);
        this.age?.markAsDirty();
      }
      this.ageRange?.disable();
      this.age?.enable();
      this.ageOperation?.enable();
      this.options = { ...this.options, disabled: true, draggableRange: false };
    }
  }

  validateRange(event: any) {
    if (event?.values) {
      if (event.values[0] > event.values[1]) {
        this.filterForm.patchValue({
          ageRange: [event.values[1], event.values[1]],
        });
      }
    }
  }

  isAgeDisabled() {
    const ageControl = this.age;
    return ageControl ? ageControl.disabled : false;
  }

  handleAgeOperationChange(ageOperation: { name: string; operation: string }) {
    this.filterForm.patchValue({ ageOperation });
  }

  handlePatientClassChange(selectedCheckboxes: CheckBox[]) {
    this.filterForm.patchValue({
      patientClass: selectedCheckboxes,
    });
  }

  handleEventChange(selectedCheckboxes: CheckBox[]) {
    this.filterForm.patchValue({
      event: selectedCheckboxes,
    });
  }

  handleEventDataRangeChange() {
    this.selectedRange = "custom";
    if (this.eventDateRange?.value[0] && this.eventDateRange?.value[1]) {
      this.filterForm.patchValue({
        eventDateRange: this.eventDateRange.value,
      });
      this.showInlineCalander = false;
    }
  }

  handleEventDateClear() {
    this.eventDateRequiredStar = true;
  }

  handleEventDataRangeClear() {
    this.selectedRange = "custom";
    this.showInlineCalander = false;
    this.eventDateRequiredStar = true;
  }

  handleFacilityChange(selected: { key: string; value: string }) {
    if (selected && !this.facility?.value?.includes(selected)) {
      this.filterForm.patchValue({
        facility: [...this.facility?.value, selected],
      });
    }
  }

  handleFacilityFilter(event: DropdownFilterEvent) {
    let query: FilterCodeset = {
      page: 1,
      limit: 100,
      type: CODESET_LOOKUP_CONSTANTS.FACILITY_NAME,
      search: event.filter,
    };
    this.mapCodesetToFields(query);
  }

  handleFacilityChipListChange(chips: { key: string; value: string }[]) {
    this.filterForm.patchValue({ facility: chips });
  }

  advanceFiltersItems: AdvancedFilter[] = [
    {
      key: "",
      operator: "",
      value: "",
      list: [],
    },
  ];

  addComboBox() {
    if (this.advanceFiltersItems.length < 5) {
      this.advanceFiltersItems.push({
        key: "",
        operator: "",
        value: "",
        list: [],
      });
    }
  }

  handleAdvanceItemChange(advanceItem: ComboBox, index: number) {
    this.advanceFiltersItems[index].value = "";
    this.advanceFiltersItems[index].operator = "";
    this.advanceFiltersItems[index].key = advanceItem.value;
    this.disableApplyORSaveEvent.emit(true);
    this.advancedFilterRequiredStar = true;
    this.mapCodesetToFields({
      page: 1,
      limit: 30,
      type: advanceItem.value,
      search: "",
    });
    if (
      this.advanceFiltersItems[index].value !== "" &&
      this.advanceFiltersItems[index].operator != ""
    ) {
      if (this._filter) {
        this.advancedFilterRequiredStar = false;
        this.disableApplyORSaveEvent.emit(false);
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleAdvanceOperatorListChange(operator: ComboBox, index: number) {
    this.advanceFiltersItems[index].operator = operator.name;
    this.disableApplyORSaveEvent.emit(true);
    this.advancedFilterRequiredStar = true;
    if (
      this.advanceFiltersItems[index].key !== "" &&
      this.advanceFiltersItems[index].value != ""
    ) {
      if (this._filter) {
        this.advancedFilterRequiredStar = false;
        this.disableApplyORSaveEvent.emit(false);
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleAdvanceValueChange(selected: ComboBox, index: number) {
    if (selected) {
      this.advanceFiltersItems[index].value = selected.value;
      this.disableApplyORSaveEvent.emit(true);
      this.advancedFilterRequiredStar = true;
    } else {
      this.advanceFiltersItems[index].value = "";
    }
    if (
      this.advanceFiltersItems[index].key !== "" &&
      this.advanceFiltersItems[index].operator != ""
    ) {
      if (this._filter) {
        this.advancedFilterRequiredStar = false;
        this.disableApplyORSaveEvent.emit(false);
        this._filter.filterValues = this.mapFormToFilter();
      } else {
        this._filter = { filterValues: this.mapFormToFilter() };
      }
    }
  }

  handleAdvanceValueFilter(event: DropdownFilterEvent, index: number) {
    let query: FilterCodeset = {
      page: 1,
      limit: 100,
      type: this.advanceFiltersItems[index].key,
      search: event.filter,
    };
    this.mapCodesetToFields(query);
  }

  handleAdvanceValueClear() {
    this.disableApplyORSaveEvent.emit(true);
    this.advancedFilterRequiredStar = true;
  }

  handleRemoveAdvancedFilters(index: number) {
    if (this.advanceFiltersItems.length === 1) {
      this.advanceFiltersItems = [
        {
          key: "",
          operator: "",
          value: "",
          list: [],
        },
      ];
    } else {
      this.advanceFiltersItems = this.advanceFiltersItems.filter(
        (_ad, i) => index !== i
      );
    }
    if (this._filter) {
      this._filter.filterValues = this.mapFormToFilter();
    }
  }

  isAdvanceFilterAddButtonDisabled(index: number) {
    if (
      this.advanceFiltersItems[index].key === "" ||
      this.advanceFiltersItems[index].operator === "" ||
      this.advanceFiltersItems[index].value === ""
    )
      return true;
    else return false;
  }

  handleActiveIndexChange(indexes: any) {
    if (indexes?.length) {
      this.filterActiveIndexes = indexes;
    }
  }

  handleSave() {
    let filterValues = this.mapFormToFilter();
    if (filterValues.length > 0) {
      if (this._filter?.id) {
        this.save.emit({
          ...this._filter,
          filterValues: filterValues,
        });
      } else this.save.emit({ isEditing: true, filterValues: filterValues });
    }
  }

  handleApply() {
    this.first = false;
    this.heading = HEADING.APPLIED;
    this.applyFilters.emit(this._filter?.filterValues);
    this.closeSidebar();
  }

  public clickApplyFilter() {
    if (this._filter?.filterValues.length) this.handleApply();
  }

  public clickSaveFilter() {
    this.handleSave();
  }

  public clickCancel() {
    this.handleCancel();
  }

  handleCancel() {
    this.closeSidebar();
  }

  formReset() {
    this.filterForm.reset({
      eventDate: "",
      eventDateRange: null,
      radioEventDate: this.RADIO.NONE,
      eventDateTimeInterval: null,
      gender: [],
      isAgeRange: null,
      ageRange: null,
      age: null,
      ageOperation: null,
      patientClass: [],
      event: [],
      facility: [],
    });
    this.handleEventDateToggle(this.RADIO.NONE);
    this.ageRange?.setValue([0, 19]);
    this.disableinitialFields();
    this.handleAgeToggle();
    this.advanceFiltersItems = [{ key: "", operator: "", value: "", list: [] }];
    this.selectedRange = "custom";
    this.showInlineCalander = false;
    this.editedFilter = null;
  }

  resetFilters() {
    this.resetEvent.emit();
    this._filter = null;
    if (this.heading != HEADING.SELECTED) this.applyFilters.emit([]);
    this.heading = HEADING.SELECTED;
    this.formReset();
    this.disableApplyORSaveEvent.emit(true);
    this.closeSidebar();
  }

  handleChangeFilter(chip: { key: string; value: string; operation: string }) {
    let remainingFilters = {
      ...this._filter,
      filterValues: this._filter?.filterValues?.filter(
        (sf) =>
          sf.key !== chip.key ||
          sf.value !== chip.value ||
          sf.operation !== chip.operation
      ),
    };
    this.formReset();
    if (
      remainingFilters?.filterValues?.length &&
      remainingFilters.filterValues.length > 0
    ) {
      this._filter = {
        id: remainingFilters.id,
        filterValues: remainingFilters.filterValues,
        name: remainingFilters.name,
      };
      this.initializeFilterForm({
        filterValues: remainingFilters.filterValues,
      });
    } else {
      this.resetFilters();
    }
  }

  initEventDate(filterItem: Filter) {
    switch (filterItem.operation) {
      case OPERATORS.IN:
        this.handleEventDateToggle(this.RADIO.INTERVAL);
        this.filterForm.patchValue({
          radioEventDate: this.RADIO.INTERVAL,
          eventDateTimeInterval: filterItem.value,
        });
        break;
      case OPERATORS.EQUALS:
        this.handleEventDateToggle(this.RADIO.SINGLE);
        this.filterForm.patchValue({
          radioEventDate: this.RADIO.SINGLE,
          eventDate: new Date(filterItem.value),
        });
        break;
      case OPERATORS.BETWEEN:
        let range = filterItem.value.split(",").map((f) => new Date(f));
        this.handleEventDateToggle(this.RADIO.RANGE);
        this.filterForm.patchValue({
          radioEventDate: this.RADIO.RANGE,
          eventDateRange: range,
        });
        break;
    }
  }

  initializeFilterForm(filter: SavedFilter) {
    filter.filterValues.forEach((filterItem) => {
      switch (filterItem.key) {
        case filterKeys.EVENT_DATE:
          this.initEventDate(filterItem);
          break;
        case filterKeys.GENDER:
          const genders = filterItem?.value?.split(",").map((value) => ({
            value: value.trim(),
            checked: true,
          }));
          this.filterForm.patchValue({
            gender: genders,
          });
          break;

        case filterKeys.AGE:
          if (filterItem?.multi) {
            const ageValues = filterItem.value?.split(",");
            this.filterForm.patchValue({
              isAgeRange: true,
              ageRange: [Number(ageValues[0]), Number(ageValues[1])],
              ageOperation: { name: "BETWEEN", operation: "BETWEEN" },
            });
            this.handleAgeToggle();
          } else {
            this.filterForm.patchValue({
              isAgeRange: false,
              age: Number(filterItem.value),
              ageOperation: {
                name: filterItem.operation,
                operation: filterItem.operation,
              },
            });
            this.handleAgeToggle();
          }
          break;

        case filterKeys.PATIENT_CLASS:
          this.filterForm.patchValue({
            patientClass: filterItem?.value?.split(",").map((value) => {
              return {
                value: value.trim(),
                checked: true,
              };
            }),
          });
          break;

        case filterKeys.EVENT:
          this.filterForm.patchValue({
            event: filterItem.value.split(",").map((value) => {
              return {
                value: value.trim(),
                checked: true,
              };
            }),
          });

          break;

        case filterKeys.FACILITY:
          this.filterForm.patchValue({
            facility: filterItem.value.split(",").map((value) => {
              return { value: value.trim() };
            }),
          });
          break;

        case filterKeys.ADMIT_REASON:
        case CODESET_LOOKUP_CONSTANTS.ADMIT_REASON:
        case filterKeys.DISCHARGE_DISPOSITION:
        case CODESET_LOOKUP_CONSTANTS.DISCHARGE_DISPOSITION:
        case filterKeys.DX_CODE:
        case CODESET_LOOKUP_CONSTANTS.DX_CODE:
        case filterKeys.PCP_NAME:
        case CODESET_LOOKUP_CONSTANTS.PCP_NAME:
          this.updateAdvancedFilters(filterItem);
          break;
      }
    });
  }

  updateAdvancedFilters(filterItem: Filter) {
    this.advanceFiltersItems.push({
      key: filterItem.key,
      operator: filterItem.operation,
      value: filterItem.value,
      list: [],
    });
    this.mapCodesetToFields({
      page: 1,
      limit: 30,
      type: filterItem.key,
      search: "",
    });
    this.advanceFiltersItems = this.advanceFiltersItems.filter(
      (af) => af.key != ""
    );
    if (this._filter) {
      this._filter.filterValues = this.mapFormToFilter();
    } else {
      this._filter = { filterValues: this.mapFormToFilter() };
    }
  }

  formatEventDate(date: Date) {
    return this.datePipe.transform(date, "MM/dd/yyyy");
  }

  mapAgeValue() {
    if (this.isAgeRange?.value) {
      return this.ageRange?.value.toString();
    } else if (this.age?.value && this.age.valid) {
      return this.age?.value + "";
    } else {
      return null;
    }
  }

  mapEventDateValue() {
    switch (this.radioEventDate?.value) {
      case this.RADIO.NONE:
        return null;
      case this.RADIO.INTERVAL:
        return this.eventDateTimeInterval?.value;
      case this.RADIO.SINGLE:
        return this.formatEventDate(this.eventDate?.value);
      case this.RADIO.RANGE:
        return this.eventDateRange?.value
          ?.map((edr: Date) => this.formatEventDate(edr))
          .toString();
    }
  }

  mapEventDateOperation() {
    switch (this.radioEventDate?.value) {
      case this.RADIO.NONE:
        return null;
      case this.RADIO.INTERVAL:
        return OPERATORS.IN;
      case this.RADIO.SINGLE:
        return OPERATORS.EQUALS;
      case this.RADIO.RANGE:
        return OPERATORS.BETWEEN;
      default:
        return null;
    }
  }

  mapFormToFilter(): Filter[] {
    let newFilterState = [
      {
        key: filterKeys.EVENT_DATE,
        value: this.mapEventDateValue(),
        operation: this.mapEventDateOperation(),
        multi: this.radioEventDate?.value == this.RADIO.RANGE,
      },
      {
        key: filterKeys.GENDER,
        value: this.gender?.value?.map((g: CheckBox) => g.value).toString(),
        operation: this.gender?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.gender?.value?.length > 1,
      },
      {
        key: filterKeys.AGE,
        value: this.mapAgeValue(),
        operation: this.isAgeRange?.value
          ? "BETWEEN"
          : this.ageOperation?.value?.name,
        multi: this.isAgeRange?.value,
      },
      {
        key: filterKeys.PATIENT_CLASS,
        value: this.patientClass?.value
          ?.map((sc: CheckBox) => sc.value)
          .toString(),
        operation: this.patientClass?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.patientClass?.value?.length > 1,
      },
      {
        key: filterKeys.EVENT,
        value: this.event?.value?.map((sc: CheckBox) => sc.value).toString(),
        operation: this.event?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.patientClass?.value?.length > 1,
      },
      {
        key: filterKeys.FACILITY,
        value: this.facility?.value?.map((sc: ComboBox) => sc.value).toString(),
        operation: this.facility?.value?.length > 1 ? "IN" : "EQUALS",
        multi: this.facility?.value?.length > 1,
      },
      ...this.advanceFiltersItems.map((af) => {
        return {
          key: af.key,
          value: af.value,
          operation: af.operator,
          multi: false,
        };
      }),
    ].filter(
      (f) =>
        f.value !== undefined &&
        f.value !== null &&
        f.value !== "" &&
        f.operation !== undefined &&
        f.operation !== null &&
        f.operation !== ""
    );
    if (newFilterState.length > 0 && this.isFormValid()) {
      this.disableApplyORSaveEvent.emit(false);
    } else {
      this.disableApplyORSaveEvent.emit(true);
    }
    return newFilterState;
  }

  mapCodesetToFields(query: FilterCodeset) {
    this.getCodesetLookup(query)
      .pipe(
        map((res: FilterCodesetResponse) =>
          res.data.map((d: DataItem) => {
            return { name: d.display ?? d.value, value: d.value };
          })
        )
      )
      .subscribe({
        next: (data) => {
          if (data && data.length && data.length > 0) {
            switch (query.type) {
              case CODESET_LOOKUP_CONSTANTS.FACILITY_NAME:
                this.facilityList = data;
                break;
              case CODESET_LOOKUP_CONSTANTS.ADMIT_REASON:
              case CODESET_LOOKUP_CONSTANTS.DISCHARGE_DISPOSITION:
              case CODESET_LOOKUP_CONSTANTS.DX_CODE:
              case CODESET_LOOKUP_CONSTANTS.PCP_NAME:
                this.advanceFiltersItems.forEach((afi) => {
                  if (afi.key === query.type) {
                    afi.list = data;
                  }
                });
            }
          }
        },
      });
  }

  closeSidebar() {
    if (this.heading !== HEADING.APPLIED) {
      this.formReset();
    }
    this.closeSidebarEvent.emit();
  }

  isFormValid() {
    let isValid = true;

    if (this.isAgeRange?.value == false && this.age?.valid == false) {
      isValid = false;
      this.ageRequiredStar = true;
    }

    switch (this.radioEventDate?.value) {
      case this.RADIO.INTERVAL:
        if (!this.eventDateTimeInterval?.valid) {
          isValid = false;
        }
        break;
      case this.RADIO.SINGLE:
        if (!this.eventDate?.valid) {
          isValid = false;
        }
        break;
      case this.RADIO.RANGE:
        if (
          !this.eventDateRange?.valid ||
          this.eventDateRange?.value[0] == null ||
          this.eventDateRange?.value[1] == null
        ) {
          isValid = false;
        }
        break;
    }

    if (isValid) {
      this.ageRequiredStar = false;
      this.eventDateRequiredStar = false;
    }
    return isValid;
  }
}
