<div class="w-full overflow-auto"> 
  <div class="mxn-detail-table-wraper">
    <div class="flex justify-content-between gap-4 min-w-max">
      <div class="col-6 flex flex-column gap-2 p-0">
        <ng-container *ngFor="let detail of firstHalf">
          <div class="flex align-items-center gap-2 p-0">
            <div class="mxn-td-label w-11rem">{{ detail.label }}:</div>
            <div class="mxn-td-info">{{ detail.value }}</div>
          </div>
        </ng-container>
      </div>
      <div class="col-5 flex flex-column gap-2 p-0">
        <ng-container *ngFor="let detail of secondHalf">
          <div class="flex align-items-center gap-2">
            <div class="mxn-td-label w-11rem">{{ detail.label }}:</div>
            <div class="mxn-td-info">{{ detail.value }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>