<div *ngFor="let chip of chips" class="chip-wrapper">
  <span class="chip" [ngSwitch]="chip.key">
    <span *ngSwitchCase="filterKeys.EVENT_DATE">
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngIf="chip.operation">{{ (""+chip.operation| keyFormatter) + " " }}</span>
      <span *ngIf="chip.value">{{ chip.value | keyFormatter }}</span>
    </span>
    
    <span *ngSwitchCase="filterKeys.AGE">
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngIf="chip.operation">{{ (chip.operation| keyFormatter) + " " }}</span>
      <span *ngIf="chip.value">{{ ""+chip.value | keyFormatter }}</span>
    </span>

    <span *ngSwitchCase="filterKeys.GENDER" class="flex align-items-center gap-1">
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngFor="let gender of chip.value?.split(',');let i = index" class="flex align-items-center gap-1">
        <img *ngIf="gender == 'male'" src="/assets/Male.svg" alt="profile"/>
        <img *ngIf="gender == 'female'" src="/assets/Female.svg" alt="profile"/>
        <img *ngIf="gender == 'other'" src="/assets/Other.svg" alt="profile"/>
        <span>
          <span>{{(gender | keyFormatter)}}</span>
          <span *ngIf="chip.value?.split(',')?.length && chip.value.split(',').length!==i+1">{{", "}}</span>
        </span>
      </span>
    </span>

    <span
      *ngSwitchCase="filterKeys.PATIENT_CLASS"
    >
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngIf="chip.value">{{ chip.value | keyFormatter }}</span>
    </span>

    <span
      *ngSwitchCase="filterKeys.EVENT"
    >
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngIf="chip.value">{{ chip.value | keyFormatter }}</span>
    </span>
    <span

      *ngSwitchCase="filterKeys.FACILITY"
    >
      <span *ngIf="chip.value">{{ chip.value | keyFormatter }}</span>
    </span>

    <span *ngSwitchDefault>
      <span class="chip-label" *ngIf="chip.key">{{ (chip.key | keyFormatter) + ": " }}</span>
      <span *ngIf="chip.operation">{{
        (chip.operation | keyFormatter) + " "
      }}</span>
      <span *ngIf="chip.value">{{ chip.value | keyFormatter }}</span>
    </span>

    <span *ngIf="removable" class="chip-removeicon">
      <em class="pi pi-times chip-remove" (click)="removeChip(chip)"></em>
    </span>
  </span>
</div>
