import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { NotificationComponent } from "./notification/notification.component";
import { MultiSelectModule } from "primeng/multiselect";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { SidebarModule } from "primeng/sidebar";
import { ButtonModule } from "primeng/button";
import { AccordionModule } from "primeng/accordion";
import { PaginatorModule } from "primeng/paginator";
import { ImageModule } from "primeng/image";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TableModule } from "primeng/table";
import { ToggleButtonModule } from "primeng/togglebutton";
import { DetailPanelComponent } from "./ui/organism/detail-panel/detail-panel.component";
import { TableComponent } from "./ui/organism/table/table.component";
import { DetailInfoComponent } from "./ui/organism/detail-info/detail-info.component";
import { CommentCardComponent } from "./ui/organism/comment-card/comment-card.component";
import { NotificationDetailComponent } from "./notification/components/notification-detail/notification-detail.component";
import { routes } from "./app.routes";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "./shared/shared.module";

import { FiltersComponent } from "./filters/filters.component";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { PanelModule } from "primeng/panel";
import { AtomModule } from "./ui/atom/atom.module";
import { CheckboxModule } from "primeng/checkbox";
import { MoleculeModule } from "./ui/molecule/molecule.module";
import { SliderModule } from "primeng/slider";
import { TooltipModule } from "primeng/tooltip";
import { FilterFormComponent } from "./filter-form/filter-form.component";
import { provideHttpClient } from "@angular/common/http";
import { RadioButtonModule } from "primeng/radiobutton";
import { CalendarModule } from "primeng/calendar";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { NotificationListComponent } from "./notification/components/notification-list/notification-list.component";
import { PaginationComponent } from "./ui/molecule/pagination/pagination.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { DialogModule } from "primeng/dialog";

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    FiltersComponent,
    FilterFormComponent,
    DetailPanelComponent,
    TableComponent,
    DetailInfoComponent,
    CommentCardComponent,
    NotificationDetailComponent,
    NotificationListComponent,
    PaginationComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    FormsModule,
    TableModule,
    ReactiveFormsModule,
    MultiSelectModule,
    BrowserAnimationsModule,
    InputGroupModule,
    InputGroupAddonModule,
    SidebarModule,
    ButtonModule,
    AccordionModule,
    PaginatorModule,
    InputGroupModule,
    ImageModule,
    OverlayPanelModule,
    ToggleButtonModule,
    TabViewModule,
    TabMenuModule,
    AtomModule,
    MoleculeModule,
    SliderModule,
    TooltipModule,
    RadioButtonModule,
    CalendarModule,
    PanelModule,
    CheckboxModule,
    NgxSliderModule,
    TableModule,
    ProgressSpinnerModule,
    ToastModule,
    DialogModule,
    RouterModule.forRoot(routes),
  ],
  providers: [provideHttpClient(), DatePipe, MessageService],
  exports: [RouterModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
