<ng-container>
  <p-table [value]="data" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of getColumnKeys(); let i = index"
          [ngClass]="headerClasses[i]"
        >
          {{ columns[col] }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-dataObj>
      <tr>
        <td
          *ngFor="let col of getColumnKeys(); let i = index"
          [ngClass]="bodyClasses[i]"
        >
          <ng-container *ngIf="col === 'action'; else elseBlock">
            <div [innerHTML]="getActionCellData(dataObj[col])"></div>
          </ng-container>
          <ng-template #elseBlock>
            {{ dataObj[col] }}
          </ng-template>
        </td>
      </tr>
      <tr *ngIf="dataObj?.testData?.length > 0">
        <td colspan="12" class="">
          <ng-container>
            <p-table
              [value]="dataObj.testData"
              [tableStyle]="{ 'min-width': '50rem' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngFor="let col of getNestedColumnKeys(); let i = index"
                    [ngClass]="nestedHeaderClasses[i]"
                  >
                    {{ nestedColumns?.[col] }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-nestedDataObj>
                <tr>
                  <td
                    *ngFor="let col of getNestedColumnKeys(); let i = index"
                    [ngClass]="nestedBodyClasses[i]"
                  >
                    <ng-container *ngIf="col === 'action'; else elseBlock">
                      <div
                        [innerHTML]="getActionCellData(nestedDataObj[col])"
                      ></div>
                    </ng-container>
                    <ng-template #elseBlock>
                      {{ nestedDataObj[col] }}
                    </ng-template>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
