import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { ServiceClientService } from "./service-client.service";
import { environment } from "../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { endpoints } from "../../environments/endpoints";
import { FilterCodeset, SavedFilter } from "../models/filter.model";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(public serviceClientService: ServiceClientService) {}

  getAllSavedFilters(): Observable<SavedFilter[]> {
    // return of([])
    return this.serviceClientService.get(endpoints.filters).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error fetching saved filters:", error);
        return of([]);
      })
    );
  }

  getCodeSet(query: FilterCodeset): Observable<any> {
    return this.serviceClientService.post(endpoints.filtersCodesetLookup, query);
  }

  saveFilter(filter: SavedFilter): Observable<any> {
    if (filter.id) {
      return this.serviceClientService.put(
        endpoints.filters + "/" + filter.id,
        {
          name: filter.name,
          filters: filter.filterValues,
        }
      );
    } else {
      return this.serviceClientService.post(endpoints.filters, {
        name: filter.name,
        filters: filter.filterValues,
      });
    }
  }

  delete(filter: SavedFilter): Observable<any> {
    if (filter && filter.id) {
      return this.serviceClientService.delete(
        endpoints.filters + "/" + filter.id
      );
    } else {
      return of(null);
    }
  }
}
