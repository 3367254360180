import { Injectable } from "@angular/core";
import { catchError, Observable, of } from "rxjs";
import { ServiceClientService } from "./service-client.service";
import { environment } from "../../environments/environment";
import { HttpErrorResponse } from "@angular/common/http";
import { endpoints } from "../../environments/endpoints";

export interface Filter {
  key: string;
  value: string;
  operation: string;
  metaData?: any;
  multi: boolean;
  state?: string;
}
export interface SavedFilter {
  id?: number;
  name?: string;
  isEditing?: boolean | null;
  filterValues: Filter[];
  updated_at?: string,
  user?: {
    first_name: string,
    last_name: string
  }
}

export interface FilterCodeset{
  page: number,
  limit: number,
  type: string,
  search: string
}

export interface DataItem {
  id: string;
  created_at: string;
  updated_at: string;
  value: string;
  type: string;
  display: string | null;
  notes: string | null;
}

export interface MetaData {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

export interface FilterCodesetResponse {
  data: DataItem[];
  metaData: MetaData;
}

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(public serviceClientService: ServiceClientService) {}

  getAllSavedFilters(): Observable<SavedFilter[]> {
    // return of([])
    return this.serviceClientService.get(endpoints.filters).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error("Error fetching saved filters:", error);
        return of([]);
      })
    );
  }

  getCodeSet(query: FilterCodeset): Observable<any> {
    return this.serviceClientService.post(endpoints.filtersCodesetLookup, query);
  }

  saveFilter(filter: SavedFilter): Observable<any> {
    if (filter.id) {
      return this.serviceClientService.put(
        endpoints.filters + "/" + filter.id,
        {
          name: filter.name,
          filters: filter.filterValues,
        }
      );
    } else {
      return this.serviceClientService.post(endpoints.filters, {
        name: filter.name,
        filters: filter.filterValues,
      });
    }
  }

  delete(filter: SavedFilter): Observable<any> {
    if (filter && filter.id) {
      return this.serviceClientService.delete(
        endpoints.filters + "/" + filter.id
      );
    } else {
      return of(null);
    }
  }
}
