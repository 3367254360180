import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KeyMappingService {
  private jsonFilePath = 'assets/key-mapping.json';
  private cachedKeyMapping$: Observable<{ [key: string]: string }> | null = null;

  constructor(private http: HttpClient) {}

  getKeyMapping(): Observable<{ [key: string]: string }> {
    if (!this.cachedKeyMapping$) {
      this.cachedKeyMapping$ = this.http.get<{ [key: string]: string }>(this.jsonFilePath).pipe(
        shareReplay(1)
      );
    }
    return this.cachedKeyMapping$;
  }
}
