import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  INotification,
  NOTIFICATION_STATUS,
} from "../../../models/notification.model";
import { NotificationService } from "../../../services/notification/notification.service";
import { getADTCodeLabel } from "../../../mock/notification.mock";
import { MessageService } from "primeng/api";

@Component({
  selector: "mxn-notification-list",
  templateUrl: "./notification-list.component.html",
  styleUrl: "./notification-list.component.scss",
})
export class NotificationListComponent {
  constructor(
    private notificationService: NotificationService,
    private messageService: MessageService
  ) {}
  @Input() notifications: INotification[] = [];
  @Input() loading: boolean = true;
  @Input() totalNotifications: number = 0;

  selectedNotification: any;
  activeIndex: number | null = null;

  @Output() onNotificationSelect: EventEmitter<INotification> =
    new EventEmitter<INotification>();
  @Output() getNotificationCall: EventEmitter<INotification> =
    new EventEmitter<INotification>();
  @Output() scrollToComments: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateOpenedNotification: EventEmitter<INotification> =
    new EventEmitter<INotification>();

  ngOnInit() {
    this.setActiveTabIndex();
  }

  setActiveTabIndex() {
    const index = this.notifications.findIndex(
      (notification) => notification.id === this.selectedNotification?.id
    );
    this.activeIndex = index !== -1 ? index : null;
  }

  onNotificationClick(id: any) {
    const foundNotification = this.notifications?.find(
      (notification) => notification.id === id
    );
    if (foundNotification) {
      this.selectedNotification = foundNotification;
      this.onNotificationSelect.emit(foundNotification);
      this.notifications = this.notifications.map((notification) => {
        if (notification.id === id) {
          notification.notification_reads =
            notification.notification_reads?.length > 0
              ? notification.notification_reads
              : [{ read_at: "", created_at: "", updated_at: "", id: "" }];
        }
        return notification;
      });
    }
  }
  updateNotificationStatusCall(
    notificationId: string,
    status: string,
    previousStatus: string
  ) {
    this.notificationService
      .updateNotificationStatus(notificationId, {
        newStatus: status,
        currentStatus: previousStatus,
      })
      .subscribe({
        next: (res) => {
          this.notifications = this.notifications.map((notification) => {
            if (notification.id === notificationId) {
              notification.status = status;
            }
            return notification;
          });
          this.updateOpenedNotification.emit({
            ...this.selectedNotification,
            id: notificationId,
            previousStatus,
            status,
          });
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Status updated successfully",
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while updating status",
          });
        },
      });
  }
  getPanelNames(notification: INotification) {
    return notification.notificationsPanels?.map((item) => {
      return item.panel.display_name;
    });
  }
  getCardStyleClasses = (status: string) => {
    switch (status) {
      case NOTIFICATION_STATUS.COMPLETED:
        return "acc-tab-completed";
      case NOTIFICATION_STATUS.IN_PROGRESS:
        return "acc-tab-inprogress";
      case NOTIFICATION_STATUS.NOT_STARTED:
      default:
        return "acc-tab-notstarted";
    }
  };
  scrollToCommentsHandler() {
    this.scrollToComments.emit();
  }
  getADTLabel(code: string) {
    const label = getADTCodeLabel(code);
    if (label === code) {
      return label;
    }
    return label + " (" + code + ")";
  }

  makeNotificationReadUnread(notificationId: string, markAsRead: boolean) {
    this.notificationService
      .updateNotificationReadUnread(notificationId, markAsRead)
      .subscribe({
        next: (res) => {
          this.notifications = this.notifications.map((notification) => {
            if (notification.id === notificationId) {
              notification.notification_reads = markAsRead
                ? [{ read_at: "", created_at: "", updated_at: "", id: "" }]
                : [];
            }
            return notification;
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.message || "Error while updating status",
          });
        },
      });
  }
}
