import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { HttpErrorHandlerService } from "./http-error-handler.service";
import { StateService } from "./state.service";
import { decodeToken } from "../util/common.util";

@Injectable({
  providedIn: "root",
})
export class ServiceClientService {
  private defaultHeaders = new HttpHeaders({
    "Content-Type": "application/json",
    Platform: "web",
  });

  constructor(
    private readonly http: HttpClient,
    private readonly httpErrorHandlerService: HttpErrorHandlerService,
    private readonly notificationState: StateService
  ) {}

  get<TResponse>(
    endPoint: string,
    params?: HttpParams
  ): Observable<TResponse | any> {
    const headers = this.createHeader();
    return this.http
      .get<TResponse>(endPoint, { headers: headers, params: params })
      .pipe(
        map((resp: any) => resp),
        catchError((err) => {
          this.httpErrorHandlerService.handleError(err);
          return throwError(() => err.error);
        })
      );
  }

  post<TRequest, TResponse>(
    endPoint: string,
    request: TRequest,
    isBlob: boolean = false
  ): Observable<TResponse | any> {
    const headers = this.createHeader();
    if (isBlob) {
      return this.http
        .post(endPoint, request, {
          headers,
          responseType: "blob",
        })
        .pipe(
          map((resp) => resp),
          catchError((err) => {
            if (err.error instanceof Blob) {
              return new Observable((observer) => {
                const reader = new FileReader();
                reader.onload = () => {
                  try {
                    const errorText = reader.result as string;
                    const parsedError = JSON.parse(errorText);
                    observer.error(parsedError);
                  } catch (parseError) {
                    observer.error(err);
                  }
                };
                reader.onerror = () => observer.error(err);
                reader.readAsText(err.error);
              });
            } else {
              this.httpErrorHandlerService.handleError(err);
              return throwError(() => err);
            }
          })
        );
    }

    return this.http.post<any>(endPoint, request, { headers: headers }).pipe(
      map((resp) => resp),
      catchError((err) => {
        this.httpErrorHandlerService.handleError(err);
        return throwError(() => err.error);
      })
    );
  }

  put<TRequest, TResponse>(
    endPoint: string,
    request: TRequest
  ): Observable<TResponse | any> {
    const headers = this.createHeader();
    return this.http
      .put<TResponse>(endPoint, request, { headers: headers })
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          this.httpErrorHandlerService.handleError(err);
          return throwError(() => err.error);
        })
      );
  }

  patch<TRequest, TResponse>(
    endPoint: string,
    request: TRequest
  ): Observable<TResponse | any> {
    const headers = this.createHeader();

    return this.http
      .patch<TResponse>(endPoint, request, { headers: headers })
      .pipe(
        map((resp) => resp),
        catchError((err) => {
          this.httpErrorHandlerService.handleError(err);
          return throwError(() => err.error);
        })
      );
  }

  delete<TResponse>(
    endPoint: string
  ): Observable<TResponse | HttpErrorResponse> {
    const headers = this.createHeader();
    return this.http.delete<TResponse>(endPoint, { headers: headers }).pipe(
      map((resp: any) => resp),
      catchError((err) => {
        this.httpErrorHandlerService.handleError(err);
        return throwError(() => err.error);
      })
    );
  }

  createHeader() {
    return {
      "Content-Type": "application/json",
      "x-csrf-token": this.notificationState.getCSRFToken(),
      Authorization: this.notificationState.getAccessToken(),
      ...this.notificationState.getMXRequestHeaderFields(),
    } as any;
  }

  checkTokenValidity() {
    const token = this.notificationState.getAccessToken();
    const decodedToken = decodeToken(token);
    if (!token || decodedToken.exp * 1000 < Date.now()) {
      this.notificationState.logout();
      return false;
    }
    return true;
  }
}
