<div
  class="mxn-notifyconts-row flex justify-content-between align-items-end gap-4 border-bottom-1 border-gray-300 pb-1"
>
  <div class="flex-1 mxn-notify-comments px-0 py-2">
    <div class="" *ngIf="!isEditing">
      <div class="flex gap-2 mb-2">
        <div class="mxn-notify-by">
          {{ commentedBy }}
        </div>
        <div class="mxn-notify-date">
          {{ comment.updated_at | date : "MM-dd-yyyy hh:mm a" }}
        </div>
      </div>
      <div class="mxn-notify-commenttext">
        {{ commentValue }}
      </div>
    </div>
    <div class="" *ngIf="isEditing">
      <input
        type="text"
        value="{{ commentValue }}"
        (change)="onChangeComment($event)"
        class="pInputText mxn-editComment-field"
        placeholder="Type Comment here...."
      />
    </div>
  </div>

  <div class="min-w-10rem">
    <div
      *ngIf="isEditable"
      class="mxn-notify-commentEdit justify-content-between gap-2 pr-4 pb-2"
    >
      <button
        *ngIf="isEditable && isEditing"
        class="bg-transparent border-none cursor-pointer"
        (click)="onSave()"
      >
        <img src="/assets/save.svg" alt="Edit" />
      </button>

      <button
        *ngIf="isEditable && !isEditing"
        class="bg-transparent border-none cursor-pointer"
        (click)="toggleEditView()"
        [disabled]="startedEditing"
      >
        <img src="/assets/edit-icon.svg" alt="Edit" />
      </button>

      <button
        *ngIf="isDeletable"
        class="bg-transparent border-none cursor-pointer"
        (click)="onDelete()"
      >
        <img src="/assets/trash.svg" alt="Delete" />
      </button>
    </div>
  </div>
</div>
