import { NOTIFICATION_STATUS } from "../models/notification.model";

export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function toCamelCase(str: string): string {
  return str
    ?.replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .split(/[\s-]+/)
    .map((word) => capitalizeFirstLetter(word))
    .join(" ")
    .trim();
}

export function decodeToken(token: string): any {
  try {
    // JWT structure: header.payload.signature
    const payload = token.split(".")[1]; // Get the payload part
    const decodedPayload = JSON.parse(atob(payload)); // Decode and parse the payload
    return decodedPayload;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

export const getFormatedCountByStatus = (countByStatus: any) => {
  if (!countByStatus) {
    return {
      [NOTIFICATION_STATUS.NOT_STARTED]: 0,
      [NOTIFICATION_STATUS.IN_PROGRESS]: 0,
      [NOTIFICATION_STATUS.COMPLETED]: 0,
      all: 0,
    };
  }
  let formattedCountByStatus = {} as any;
  [
    NOTIFICATION_STATUS.NOT_STARTED,
    NOTIFICATION_STATUS.IN_PROGRESS,
    NOTIFICATION_STATUS.COMPLETED,
    "all",
  ].forEach((status) => {
    formattedCountByStatus[status] = countByStatus[status] || 0;
  });
  return formattedCountByStatus;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

export const formatDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate.replace(/\//g, "-").replace(/,/g, "");
};
