import { Pipe, PipeTransform } from "@angular/core";
import { KeyMappingService } from "../../services/key-mapping.service";

@Pipe({
  name: "keyFormatter",
  pure: false,
})
export class KeyFormatterPipe implements PipeTransform {
  keyMapping: any = {};

  constructor(private keyMappingService: KeyMappingService) {
    this.keyMappingService.getKeyMapping().subscribe((data) => {
      this.keyMapping = data;
    });
  }

  transform(value: any): string {
    if (!value) return value;

    const formattedValue = this.keyMapping[value.toLowerCase()];

    if (formattedValue) {
      return formattedValue;
    }

    return value
      // .toLowerCase()
      .replace(/,\s?/g, ", ")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char: string) => char.toUpperCase());
  }
}
