import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServiceClientService } from "../service-client.service";
import { endpoints } from "../../../environments/endpoints";
import { IResponse } from "../../models/response.model";

import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { INotification } from "../../models/notification.model";
import { IRequest } from "../../models/request.modal";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private serviceClientService: ServiceClientService) {}

  // get CSRF token
  getCSRFToken(): Observable<any> {
    return this.serviceClientService.get<IResponse<String> | HttpErrorResponse>(
      `${endpoints.getCSRFToken}`
    );
  }

  getNotifications(
    requestBody: { [key: string]: any },
    params?: { [key: string]: any }
  ): Observable<any> {
    let httpParams = new HttpParams();

    // If optional params are provided, append them as query parameters
    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
    }

    // Make a POST request with the provided requestBody and optional httpParams
    return this.serviceClientService.post<IRequest<any>, IResponse<any>>(
      endpoints.notifications,
      requestBody
    );
  }

  // get notification by ID
  getNotificationById(notificationId: string): Observable<any> {
    return this.serviceClientService.get<IResponse<INotification>>(
      `${endpoints.notifications}/${notificationId}`
    );
  }

  // User Settings
  getUserSettings(): Observable<any> {
    return this.serviceClientService.get<IResponse<any>>(
      endpoints.userSettings
    );
  }

  // Update User Settings
  updateUserSettings(body: any): Observable<any> {
    return this.serviceClientService.put<IResponse<any>, any>(
      endpoints.userSettings,
      body
    );
  }
  
  getPanels(): Observable<any> {
    return this.serviceClientService.get<IResponse<any>>(
      endpoints.panels
    );
  }

  // Update Notification Status
  updateNotificationStatus(
    notificationId: string,
    status: any
  ): Observable<any> {
    return this.serviceClientService.patch<IResponse<any>, any>(
      `${endpoints.notifications}/${notificationId}/status`,
      {
        ...status,
        data: undefined,
      }
    );
  }

  downloadNotification(
    requestBody: { [key: string]: any },
    params?: { [key: string]: any }
  ): Observable<Blob> {
    let httpParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.append(key, params[key]);
      });
    }

    return this.serviceClientService.post<IRequest<any>, IResponse<any>>(
      endpoints.downloadCSV,
      requestBody,
      true
    );
  }

  getNotificationHasUpdates(): Observable<any> {
    return this.serviceClientService.get<IResponse<any>>(
      endpoints.getNotificationsHasUpdate
    );
  }

  updateNotificationReadUnread(
    notificationId: string,
    readStatus: boolean
  ): Observable<any> {
    return this.serviceClientService.patch<any, any>(
      `${endpoints.updateNotificationReadUnread}/${notificationId}`,
      {
        readStatus,
      }
    );
  }
  logout(): Observable<any> {
    return this.serviceClientService.get<IResponse<any>>(endpoints.logout);
  }
}
