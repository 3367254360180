<div class="flex justify-content-between align-items-center">
  <div class="pagination-rows">
    <p-dropdown
      class="mxn-showRows"
      [options]="rowsOptions"
      [(ngModel)]="rowsPerPage"
      optionLabel="label"
      placeholder="{{ rowsPerPage.toString() }}"
      (onChange)="changeRowsPerPage($event)"
    >
    </p-dropdown>
  </div>
  <div class="pagination">
    <button
      (click)="changePage(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="pagination-button"
    >
      <em class="pi pi-arrow-left"></em> Previous
    </button>

    <button
      *ngFor="let page of paginationArray"
      (click)="changePage(page)"
      [ngClass]="{
        'pagination-button-disabled': page === '...',
        'pagination-button': page !== '...',
        active: isActive(page)
      }"
      [disabled]="page === '...'"
    >
      {{ page }}
    </button>

    <button
      (click)="changePage(currentPage + 1)"
      [disabled]="currentPage === totalPages || totalPages === 0"
      class="pagination-button"
    >
      Next <em class="pi pi-arrow-right"></em>
    </button>
  </div>
</div>
