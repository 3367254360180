import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SavedFilter } from "../../../models/filter.model";
import { IUser } from "../../../models/user.model";

@Component({
  selector: "app-saved-filter-list",
  templateUrl: "./saved-filter-list.component.html",
  styleUrl: "./saved-filter-list.component.scss",
})
export class SavedFilterListComponent {
  @Input() sidebarVisible: boolean = false;
  @Input() filters: SavedFilter[] = [];
  @Input() currentUser: IUser | null = null;
  @Input() styleClass: string = "";
  @Input() setEdit: boolean = false;
  @Input() loading: boolean = false;

  @Output() applyFilter = new EventEmitter<any>();
  @Output() saveFilter = new EventEmitter<any>();
  @Output() editFilter = new EventEmitter<any>();
  @Output() deleteFilter = new EventEmitter<any>();

  edit = false;
  visible: boolean = false;
  toBeDeleted: SavedFilter | null = null;
  filterName: string = "";

  ngOnInit() {
    if (this.setEdit) this.edit = this.setEdit;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filters.forEach(f=>{
      f.isEditable = this.isEditable(f);
    })
    if (changes["filters"]) {
      const previousValue = changes["filters"].previousValue;
      const currentValue = changes["filters"].currentValue;
      const firstChange = changes["filters"].firstChange;
      if (currentValue && currentValue !== previousValue && !firstChange) {
        currentValue.forEach((cv: SavedFilter) => {
          if (cv.isEditing) {
            this.focusOnElementById("" + cv.id);
          }
        });
      }
    }

    if (!this.sidebarVisible) {
      this.filters = this.filters.filter((f) => f.id);
    }
  }

  focusOnElementById(elementId: string) {
    try {
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.focus();
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 0);
    } catch (error) {
      console.error("Element not found:", error);
    }
  }

  getDateFromTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  }

  getTimeFromTimestamp(timestamp: string): string {
    const date = new Date(timestamp);
    return date.toLocaleTimeString();
  }

  isEditable(filter: SavedFilter) {
    if (filter.user) {
      return this.currentUser?.email === filter.user.email;
    } else {
      return true;
    }
  }

  getToolTip(filter: SavedFilter) {
    return (
      "Updated by " +
      filter.user?.last_name +
      " " +
      filter.user?.first_name +
      " on " +
      this.getDateFromTimestamp(filter.updated_at ?? "") +
      " at " +
      this.getTimeFromTimestamp(filter.updated_at ?? "")
    );
  }

  onApplyFilter(filter: SavedFilter) {
    this.applyFilter.emit(filter);
  }

  handleRemovedChip(
    $event: { key: string; value: string },
    filter: SavedFilter
  ) {
    filter.filterValues = filter.filterValues.filter(
      (f) => f.key !== $event.key && f.value !== $event.value
    );
  }

  updateFilter(filter: SavedFilter) {
    this.filters = this.filters.map((f) => {
      if (f.id == filter.id) {
        return { ...filter };
      }
      return { ...f };
    });
  }

  onEdit(filter: SavedFilter) {
    this.filters = this.filters
      .map((f) => {
        if (f.id == filter.id) {
          return { ...f, isEditing: true };
        }
        return { ...f, isEditing: false };
      })
      .filter((f) => f.id);
    this.editFilter.emit(filter);
  }

  onSave(filter: SavedFilter) {
    this.filters = this.filters.map((f) => {
      return { ...f, isEditing: false };
    });
    this.saveFilter.emit(filter);
  }

  onDelete(filter: SavedFilter) {
    this.toBeDeleted = filter;
    this.visible = true;
  }

  onClose() {
    this.filters = this.filters.map((f) => {
      return { ...f, isEditing: false };
    });
  }

  public close() {
    this.onClose();
  }

  handleConfirmDelete() {
    this.visible = false;
    this.deleteFilter.emit(this.toBeDeleted);
    this.toBeDeleted = null;
  }
  handleCancelDelete() {
    this.visible = false;
    this.toBeDeleted = null;
  }
}
