import { IComment } from "./comment.model";

export interface INotification {
  id: string;
  comments: IComment[];
  status: string;
  title: string;
  notification_reads: {
    read_at: string;
    created_at: string;
    updated_at: string;
    id: string;
  }[];
  notificationsPanels: {
    panel: {
      display_name: string;
      id: string;
    };
  }[];
  encounter: {
    gender: string;
    name: string;
    birth_date: string;
    admit_reason: string;
    facility_name: string;
    json: any;
    [key: string]: any;
  };
  event_date: string;
  event_type: string;
}

export const NOTIFICATION_STATUS = {
  COMPLETED: "completed",
  IN_PROGRESS: "in-progress",
  NOT_STARTED: "not-started",
};
