import { Component } from '@angular/core';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrl: './button-dropdown.component.scss'
})
export class ButtonDropdownComponent {

}
