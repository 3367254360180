import { Component } from '@angular/core';

@Component({
  selector: 'app-date-range-selection',
  templateUrl: './date-range-selection.component.html',
  styleUrl: './date-range-selection.component.scss'
})
export class DateRangeSelectionComponent {

}
