import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() label: string = 'Button';
  @Input() icon: string | undefined;
  @Input() iconPos: 'left' | 'right' = 'left';
  @Input() disabled: boolean = false;
  @Input() styleClass: string | undefined;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClick.emit();
  }
}
