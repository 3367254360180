<div class="mxn-notify-accordion">
  <p-accordion
    *ngIf="!loading && notifications.length > 0; else loadingBlock"
    class="w-full"
    [multiple]="false"
    [(activeIndex)]="activeIndex"
  >
    <p-accordionTab
      *ngFor="let notification of notifications"
      [tabStyleClass]="getCardStyleClasses(notification.status)"
      (click)="onNotificationClick(notification.id)"
      (keydown)="onNotificationClick(notification.id)"
    >
      <ng-template pTemplate="header">
        <div class="w-full">
          <div class="flex justify-content-between align-items-center mb-2">
            <div
              class=""
              [ngClass]="{
                'mxn-notify-title': true,
                'font-semibold': notification?.notification_reads?.length == 0
              }"
            >
              {{ getADTLabel(notification.event_type) }}
            </div>
            <div
              class="mxn-notify-act"
              (click)="$event.stopPropagation()"
              (keydown)="$event.stopPropagation()"
            >
              <div class="mxn-notify-commints-tooltip mxn-overlay">
                <img
                  *ngIf="
                    notification?.comments && notification.comments.length > 0
                  "
                  (click)="scrollToCommentsHandler()"
                  src="/assets/chat-text.svg"
                  alt="Comments"
                  (keydown)="scrollToCommentsHandler()"
                />
                <div
                  class="mxn-overlay-ddm"
                  *ngIf="
                    notification?.comments && notification.comments.length > 0
                  "
                >
                  <div class="mxn-overlay-items">
                    <ul>
                      <li *ngFor="let comment of notification?.comments">
                        <span class="mxn-sortby-icon"
                          ><img src="/assets/exclamation.svg" alt="exclamation"
                        /></span>
                        <p class="p-0 m-0">
                          {{ comment.text }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="max-read-unreadIcon mxn-overlay">
                <img
                  *ngIf="
                    !(
                      notification?.notification_reads &&
                      notification.notification_reads.length > 0
                    )
                  "
                  src="/assets/notify-unread.svg"
                  alt="unread"
                  (click)="makeNotificationReadUnread(notification.id, true)"
                  (keydown)="makeNotificationReadUnread(notification.id, true)"
                />
                <img
                  *ngIf="
                    notification?.notification_reads &&
                    notification.notification_reads.length > 0
                  "
                  src="/assets/notify-read.svg"
                  alt="read"
                  class="mxn-notify-readicon"
                  (click)="makeNotificationReadUnread(notification.id, false)"
                  (keydown)="makeNotificationReadUnread(notification.id, false)"
                />
                <div class="mxn-overlay-ddm">
                  <div class="mxn-overlay-items w-8rem">
                    <ul>
                      <li
                        *ngIf="
                          !(
                            notification?.notification_reads &&
                            notification.notification_reads.length > 0
                          )
                        "
                        class="text-sm"
                      >
                        Make as read
                      </li>
                      <li
                        *ngIf="
                          notification?.notification_reads &&
                          notification.notification_reads.length > 0
                        "
                        class="text-sm"
                      >
                        Make as unread
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mxn-overlay mxn-status-tooltip">
                <img
                  *ngIf="notification?.status === 'not-started'"
                  src="/assets/hovered.svg"
                  alt="Not Started"
                />
                <img
                  *ngIf="notification?.status === 'in-progress'"
                  src="/assets/inprogress.svg"
                  alt="In Progress"
                />
                <img
                  *ngIf="notification?.status === 'completed'"
                  src="/assets/check.svg"
                  alt="Completed"
                />
                <div class="mxn-overlay-ddm">
                  <div class="mxn-overlay-items w-8rem">
                    <ul>
                      <li
                        [ngClass]="{
                          active: notification?.status === 'not-started',
                          notstarted: notification?.status === 'not-started'
                        }"
                        (click)="
                          notification?.status !== 'not-started' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'not-started',
                              notification.status
                            )
                        "
                        (keydown)="
                          notification?.status !== 'not-started' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'not-started',
                              notification.status
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img
                            src="/assets/hovered.svg"
                            alt="Not Started" /></span
                        >Not Started
                      </li>
                      <li
                        [ngClass]="{
                          active: notification?.status === 'in-progress',
                          inprogress: notification?.status === 'in-progress'
                        }"
                        (click)="
                          notification?.status !== 'in-progress' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'in-progress',
                              notification.status
                            )
                        "
                        (keydown)="
                          notification?.status !== 'in-progress' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'in-progress',
                              notification.status
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img
                            src="/assets/inprogress.svg"
                            alt="In Progress" /></span
                        >In Progress
                      </li>
                      <li
                        [ngClass]="{
                          active: notification?.status === 'completed',
                          discharge: notification?.status === 'completed'
                        }"
                        (click)="
                          notification?.status !== 'completed' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'completed',
                              notification.status
                            )
                        "
                        (keydown)="
                          notification?.status !== 'completed' &&
                            updateNotificationStatusCall(
                              notification.id,
                              'completed',
                              notification.status
                            )
                        "
                      >
                        <span class="mxn-sortby-icon"
                          ><img src="/assets/check.svg" alt="Completed" /></span
                        >Completed
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-content-between align-items-center mb-1">
            <div class="flex gap-2">
              <div
                class="mxn-notify-Pname"
                *ngIf="notification?.encounter?.name"
              >
                {{ notification.encounter.name }}
              </div>
              <div
                class="mxn-notify-Pgen"
                *ngIf="notification?.encounter?.gender"
              >
                [<span>{{ notification.encounter.gender }}</span
                >]
              </div>
              <div
                class="mxn-notify-Pdob"
                *ngIf="notification?.encounter?.birth_date"
              >
                DOB:
                {{ notification.encounter.birth_date | date : "MM-dd-yyyy" }}
              </div>
            </div>
            <div class="acc-tab-icon">
              <em class="pi pi-angle-down"></em>
            </div>
          </div>
          <div
            class="flex justify-content-between align-items-center mxn-notify-provider"
          >
            {{ notification?.encounter?.facility_name ?? "" }}
          </div>
        </div>
      </ng-template>
      <div class="">
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/date-icon.svg" alt="Date" />
            </div>
            <div>Event Date</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{ notification.event_date | date : "MM-dd-yyyy hh:mm a" }}
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/panel.svg" alt="Panel" />
            </div>
            <div>Panel</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{ getPanelNames(notification).slice(0, 3).toString() || "-" }}
            <span
              *ngIf="getPanelNames(notification).slice(3).length > 0"
              class="mxn-notify-bubbleSolid"
              >+{{ getPanelNames(notification).slice(3).length }}</span
            >
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/ambulance.svg" alt="Admit" />
            </div>
            <div>Admit Reason</div>
          </div>
          <div
            class="mxn-notify-Dinfo"
            *ngIf="notification?.encounter?.admit_reason"
          >
            {{ notification.encounter.admit_reason || "" }}
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mb-10">
          <div class="mxn-notify-Dlabel">
            <div class="mxn-notify-Ricon">
              <img src="./assets/calendar.svg" alt="Encounter" />
            </div>
            <div>Dates of Encounter</div>
          </div>
          <div class="mxn-notify-Dinfo">
            {{
              notification?.encounter?.json.encounter?.planned_start_date
                | date : "MM-dd-yyyy"
            }}
            -
            {{
              notification?.encounter?.json.encounter?.planned_end_date
                | date : "MM-dd-yyyy"
            }}
          </div>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
  <ng-template #loadingBlock>
    <div *ngIf="loading; else emptyBlock" class="loader">
      <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
    </div>
  </ng-template>
  <ng-template #emptyBlock>
    <div class="loader">
      <p>No notification available.</p>
    </div>
  </ng-template>
</div>
