import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

interface Checkbox {
  label: string;
  value: any;
  checked: boolean;
}

@Component({
  selector: "app-check-box",
  templateUrl: "./check-box.component.html",
  styleUrl: "./check-box.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true,
    },
  ],
})
export class CheckBoxComponent {
  @Input() styleClass: string = "";
  @Input() pCheckboxStyleClass?: string = "";

  @Input() checkboxes: Checkbox[] = [];

  @Output() checkboxChange = new EventEmitter<
    { value: any; checked: boolean }[]
  >();
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};
  private onDiabled: () => void = () => {};

  writeValue(value: { value: any; checked: boolean }[]): void {
    if (value && value.length) {
      this.checkboxes = this.checkboxes.map((checkbox) => {
        const match = value.find((v) => v.value === checkbox.value);
        return match
          ? { ...checkbox, checked: match.checked }
          : { ...checkbox, checked: false };
      });
    } else {
      this.checkboxes = this.checkboxes.map((checkbox) => ({
        ...checkbox,
        checked: false,
      }));
    }
  }

  // Registers a callback function triggered when the value of the component changes
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onCheckboxChange() {
    const selectedCheckboxes = this.checkboxes.filter((c) => c.checked);
    this.checkboxChange.emit(selectedCheckboxes);
  }
}
