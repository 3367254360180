import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() styleClass: string = '';


  @Input() value: string | number | null = null;


  @Output() valueChange: EventEmitter<string | number> = new EventEmitter<
    string | number
  >();

  onInputChange(event: any) {
    this.valueChange.emit(event);
  }
}
