import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpErrorHandlerService {
  errorHandler: Subject<any> = new Subject();

  handleError(error: HttpErrorResponse) {
    return error;
  }
}
