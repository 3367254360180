import { IComment } from "../models/comment.model";
import { IUser } from "../models/user.model";

/**
 * Enum representing the different events that can be logged in the audit log system.
 */
export enum AuditLogEvent {
  /** Event triggered when a new notification is created. */
  NOTIFICATION_CREATED_EVENT = "NOTIFICATION_CREATED_EVENT",

  /** Event triggered when a notification is deleted. */
  NOTIFICATION_DELETED_EVENT = "NOTIFICATION_DELETED_EVENT",

  /** Event triggered when a notification is updated. */
  NOTIFICATION_UPDATED_EVENT = "NOTIFICATION_UPDATED_EVENT",

  /** Event triggered when a notification status is updated. */
  NOTIFICATION_STATUS_UPDATED_EVENT = "NOTIFICATION_STATUS_UPDATED_EVENT",

  /** Event triggered when a notification is marked as read. */
  NOTIFICATION_MARKED_READ_EVENT = "NOTIFICATION_MARKED_READ_EVENT",

  /** Event triggered when a notification is marked as unread. */
  NOTIFICATION_MARKED_UNREAD_EVENT = "NOTIFICATION_MARKED_UNREAD_EVENT",

  /** Event triggered when a notification is marked as read/unread. */
  NOTIFICATION_READ_ENDPOINT = "NOTIFICATION_READ_ENDPOINT",

  /** Event triggered when a new comment is created on a notification. */
  COMMENT_CREATED_EVENT = "COMMENT_CREATED_EVENT",

  /** Event triggered when a comment is updated. */
  COMMENT_UPDATED_EVENT = "COMMENT_UPDATED_EVENT",

  /** Event triggered when a comment is deleted. */
  COMMENT_DELETED_EVENT = "COMMENT_DELETED_EVENT",

  /** Event triggered when a filter is updated. */
  FILTER_UPDATED_EVENT = "FILTER_UPDATED_EVENT",

  /** Event triggered when a filter is updated. */
  FILTER_CREATED_EVENT = "FILTER_CREATED_EVENT",

  /** Event triggered when a filter is deleted. */
  FILTER_DELETED_EVENT = "FILTER_DELETED_EVENT",

  /** Event triggered when a user updates their settings. */
  USER_SETTING_UPDATED_EVENT = "USER_SETTING_UPDATED_EVENT",

  /** Event triggered when an exception returned from endpoint. */
  EXCEPTION_ENDPOINT = "EXCEPTION_ENDPOINT",

  /** Event triggered when a user successfully logs in. */
  LOGIN_EVENT = "LOGIN_EVENT",

  /** Event triggered when a user successfully logs out. */
  LOGGED_OUT = "LOGGED_OUT",

  /** Event triggered when a login attempt fails. */
  LOGIN_FAILED = "LOGIN_FAILED",

  /** Event triggered for actions related to notifications. */
  NOTIFICATION_ENDPOINT = "NOTIFICATION_ENDPOINT",

  /** Event triggered for actions related to comments. */
  COMMENT_ENDPOINT = "COMMENT_ENDPOINT",

  // **New Events**
  /** Event triggered when a new notification is created via an endpoint. */

  /** Event triggered when any endpoint is accessed. */
  ENDPOINT_TRIGGERED = "ENDPOINT_TRIGGERED",

  /** Event triggered when a listing of filters is requested. */
  FILTER_LISTING_ENDPOINT = "FILTER_LISTING_ENDPOINT",

  /** Event triggered when the details of a notification are viewed. */
  NOTIFICATION_DETAILS_VIEWED_ENDPOINT = "NOTIFICATION_DETAILS_VIEWED_ENDPOINT",

  /** Event triggered when a listing of notifications is requested. */
  NOTIFICATION_LISTING_ENDPOINT = "NOTIFICATION_LISTING_ENDPOINT",

  NOTIFICATION_INGESTION_ENDPOINT = "NOTIFICATION_INGESTION_ENDPOINT",

  NOTIFICATION_INGESTION_EVENT = "NOTIFICATION_INGESTION_EVENT",

  NOTIFICATION_CREATED_ENDPOINT = "NOTIFICATION_CREATED_ENDPOINT",

  /** Event triggered when a notification is deleted via an endpoint. */
  NOTIFICATION_DELETED_ENDPOINT = "NOTIFICATION_DELETED_ENDPOINT",

  /** Event triggered when a notification is updated via an endpoint. */
  NOTIFICATION_UPDATED_ENDPOINT = "NOTIFICATION_UPDATED_ENDPOINT",

  /** Event triggered when a notification status is updated via an endpoint. */
  NOTIFICATION_STATUS_UPDATED_ENDPOINT = "NOTIFICATION_STATUS_UPDATED_ENDPOINT",

  /** Event triggered when a notification is marked as read via an endpoint. */
  NOTIFICATION_MARKED_READ_ENDPOINT = "NOTIFICATION_MARKED_READ_ENDPOINT",

  /** Event triggered when a new comment is created via an endpoint. */
  COMMENT_CREATED_ENDPOINT = "COMMENT_CREATED_ENDPOINT",

  /** Event triggered when a comment is updated via an endpoint. */
  COMMENT_UPDATED_ENDPOINT = "COMMENT_UPDATED_ENDPOINT",

  /** Event triggered when a comment is deleted via an endpoint. */
  COMMENT_DELETED_ENDPOINT = "COMMENT_DELETED_ENDPOINT",

  /** Event triggered when a new filter is created via an endpoint. */
  FILTER_CREATED_ENDPOINT = "FILTER_CREATED_ENDPOINT",

  /** Event triggered when a filter is updated via an endpoint. */
  FILTER_UPDATED_ENDPOINT = "FILTER_UPDATED_ENDPOINT",

  /** Event triggered when a filter is deleted via an endpoint. */
  FILTER_DELETED_ENDPOINT = "FILTER_DELETED_ENDPOINT",

  /** Event triggered when user settings are updated via an endpoint. */
  USER_SETTING_UPDATED_ENDPOINT = "USER_SETTING_UPDATED_ENDPOINT",

  /** Event triggered when data is exported from the system. */
  DATA_EXPORTED_ENDPOINT = "DATA_EXPORTED_ENDPOINT",

  /** Event triggered when the notification is exported as CSC */
  NOTIFICATION_DOWNLOAD_CSV_ENDPOINT = "NOTIFICATION_DOWNLOAD_CSV_ENDPOINT",
  /** Event triggered when internal unexpected error occurs. */
  UNEXPECTED_ERROR_EVENT = "UNEXPECTED_ERROR_EVENT",

  /** Event triggered when email sending failed occurs */
  EMAIL_FAILURE_EVENT = "EMAIL_FAILURE_EVENT",

  /** Event triggered when email sent successfully */
  EMAIL_SUCCESS_EVENT = "EMAIL_SUCCESS_EVENT",

  /** Event triggered when scheduler is trigger */
  NOTIFICATION_SCHEDULER_TRIGGERED = "NOTIFICATION_SCHEDULER_TRIGGERED",
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const formatDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDate = date.toLocaleString("en-US", options);
  return formattedDate.replace(/\//g, "-").replace(/,/g, "");
};

const patientClassMap = new Map([
  ["E", "Emergency"],
  ["I", "Inpatient"],
  ["O", "Outpatient"],
  ["COVID", "COVID"],
]);
const genderMap = new Map([
  ["M", "Male"],
  ["F", "Female"],
  ["O", "Other"],
  ["U", "Unknown"],
]);
const sexualOrientationMap = new Map([
  ["20430005", "Straight or Heterosexual"],
  ["38628009", "Gay, Lesbian or Homosexual"],
  ["42035005", "Bisexual"],
  ["Pansexual", "Pansexual"],
  ["Queer", "Queer"],
  ["Asexual", "Asexual"],
  ["Two-spirit", "Two-spirit"],
  ["Questioning/not sure", "Questioning/not sure"],
  ["7881000087104", "Choose not to disclose"],
  ["Not listed, please describe", "Not listed, please describe"],
]);

const getformatedPatientMetaData = (metaData: any) => {
  if (!metaData || metaData.length === 0) {
    return {};
  }
  const patientMetaData = metaData.reduce(
    (
      result: { [x: string]: { id: any; label: any; value: any } },
      item: { property: string | number; id: any; label: any; value: any }
    ) => {
      // Adding only desired fields under the `property` key
      result[item.property] = {
        id: item.id,
        label: item.label,
        value: item.value,
      };
      return result;
    },
    {} as Record<string, { id: string; label: string; value: string }>
  );
  return patientMetaData;
};

const getFormatedDemographicsData = (notification: any) => {
  if (notification?.patient_meta_data?.length === 0)
    return [
      {
        label: "DOB",
        value: formatDate(notification?.encounter?.birth_date) || "-",
      },
      {
        label: "Home Phone",
        value:
          notification?.encounter?.json?.encounter?.patient?.telecom?.[0]
            ?.value || "-",
      },
      {
        label: "Home Address",
        value:
          notification?.encounter?.json?.encounter?.patient?.address?.[0]
            ?.text || "-",
      },
      { label: "PCP", value: notification?.encounter?.pcp_name || "-" },
      { label: "NPI", value: notification?.encounter?.mpi_id || "-" },
      {
        label: "Gender Identity",
        value: "-",
      },
      {
        label: "Sex Assigned at Birth",
        value: "-",
      },
      { label: "Sexual Orientation", value: "-" },
      {
        label: "Deceased",
        value: notification?.encounter?.json?.encounter?.patient
          ?.deceased_boolean
          ? "Yes"
          : "No",
      },
    ];
  const patientMetaData = getformatedPatientMetaData(
    notification?.patient_meta_data
  );
  return [
    { label: "DOB", value: formatDate(notification?.encounter?.birth_date) },
    {
      label: "Home Phone",
      value:
        notification?.encounter?.json?.encounter?.patient?.telecom?.[0]?.value,
    },
    {
      label: "Home Address",
      value:
        notification?.encounter?.json?.encounter?.patient?.address?.[0]?.text,
    },
    { label: "PCP", value: notification?.encounter?.pcp_name },
    { label: "NPI", value: notification?.encounter?.mpi_id },
    {
      label: "Gender Identity",
      value: patientMetaData["GI"]?.label || "-",
    },
    {
      label: "Sex Assigned at Birth",
      value: patientMetaData["SAB"]?.label || "-",
    },
    { label: "Sexual Orientation", value: patientMetaData["SO"]?.label || "-" },
    {
      label: "Deceased",
      value: notification?.encounter?.json?.encounter?.patient?.deceased_boolean
        ? "Yes"
        : "No",
    },
  ];
};
const getAuditLogActionText = (auditLog: any) => {
  switch (auditLog.type) {
    case AuditLogEvent.NOTIFICATION_CREATED_EVENT:
      return "Notification Created";
    case AuditLogEvent.NOTIFICATION_STATUS_UPDATED_EVENT:
      return auditLog?.meta_data?.new_status ?? "Status Updated";
    case AuditLogEvent.COMMENT_CREATED_EVENT:
    case AuditLogEvent.COMMENT_UPDATED_EVENT:
      return "Commented";
    case AuditLogEvent.COMMENT_DELETED_EVENT:
      return "Comment Deleted";
    case AuditLogEvent.NOTIFICATION_MARKED_READ_EVENT:
      return "Read";
    case AuditLogEvent.NOTIFICATION_MARKED_UNREAD_EVENT:
      return "Unread";
    default:
      return "-";
  }
};
export const notificationMock = (notification: any, currentUser: IUser) => {
  const patientMetaData = getformatedPatientMetaData(
    notification?.patient_meta_data
  );
  return {
    id: notification?.id,
    eventType: notification?.event_type || "-",
    patientName:
      `${notification?.encounter?.json?.encounter?.patient?.name?.given[0]} ${notification?.encounter?.json?.encounter?.patient?.name?.family}` ||
      "-",

    age:
      new Date().getFullYear() -
      new Date(notification?.encounter?.birth_date).getFullYear(),

    gender: notification?.encounter?.gender,

    statusBadge:
      notification?.status && !notification?.error
        ? notification?.status
        : "not-started",

    demographics: getFormatedDemographicsData(notification),

    organisationPanels:
      notification?.notificationsPanels?.map((notificationPanel: any) => ({
        panelName:
          notificationPanel.panel.display_name ||
          notificationPanel.panel.panel_name,
        mrn: notificationPanel?.patient_identifier,
      })) || [],

    encounter: [
      { label: "Facility", value: notification?.encounter?.facility_name || "-" },
      {
        label: "Patient Class",
        value:
          patientClassMap.get(
            notification?.encounter?.json?.encounter?.class
          ) ||
          notification?.encounter?.json?.encounter?.class ||
          "-",
      },
      {
        label: "Event Type",
        value:
          notification?.encounter?.json?.encounter?.event_type_description ||
          "-",
      },
      {
        label: "Event Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_end_date
          ) || "-",
      },
      {
        label: "Admit Source",
        value:
          notification?.encounter?.json?.encounter?.admission_source || "-",
      },
      {
        label: "Admit Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_start_date
          ) || "-",
      },
      {
        label: "Admitting Clinician",
        value: `${notification?.encounter?.json?.encounter?.admitting_clinician_given_name} ${notification?.encounter?.json?.encounter?.admitting_clinician_family_name}`,
      },
      {
        label: "Admission Type",
        value: notification?.encounter?.json?.encounter?.admission_type || "-",
      },
      {
        label: "Hospital Service",
        value: notification?.encounter?.json?.encounter?.service_type || "-",
      },
      {
        label: "Discharge Date/Time",
        value:
          formatDate(
            notification?.encounter?.json?.encounter?.planned_end_date
          ) || "-",
      },
      {
        label: "Discharge Disposition",
        value:
          notification?.encounter?.discharge_disposition ||
          "Discharge with approval",
      },
      {
        label: "No. of ED Visits",
        value: patientMetaData["IP6mo"]?.value
          ? `${patientMetaData["IP6mo"].value} in the last 6 months`
          : "-",
      },
      {
        label: "No. of IP Visits",
        value: patientMetaData["ER6mo"]?.value
          ? `${patientMetaData["IP6mo"].value} in the last 6 months`
          : "-",
      },
      {
        label: "Patient Location",
        value: notification?.encounter?.patient_location || "-",
      },
    ],

    conditions:
      (notification?.encounter?.json?.encounter?.conditions.length &&
        notification?.encounter?.json?.encounter?.conditions?.map(
          (condition: any) => ({
            code: formatDateTime(condition.recorded_date) || "-",
            name: condition.type,
            category: condition.code,
            quantity: condition.display,
            makras: "Marked as",
            action: "*Completed",
            performedby: "*June Ryan",
          })
        )) ||
      [],

    laboratoryResults:
      (notification?.encounter?.json?.encounter?.service_request?.observations
        ?.length && [
        {
          test:
            notification?.encounter?.json?.encounter?.service_request?.test ||
            "Unknown Test",
          observationDate:
            formatDateTime(
              notification?.encounter?.json?.encounter?.service_request
                ?.result_date
            ) || "-",
          lastResultDate:
            formatDateTime(
              notification?.encounter?.json?.encounter?.service_request
                .last_status_date
            ) || "-",
          source:
            notification?.encounter?.json?.encounter?.service_request?.source ||
            "-",
          status:
            notification?.encounter?.json?.encounter?.service_request?.status ||
            "-",
          testData:
            notification?.encounter?.json?.encounter?.service_request?.observations?.map(
              (obs: any) => ({
                item: obs?.display || "Unknown Item",
                date: formatDateTime(obs?.observation_date) || "-",
                resultValue: obs?.value || "No Result",
                unit: obs?.unit || "-",
                referenceRange: obs?.reference_range || "N/A",
                messageFlag: obs?.interpretation || "",
              })
            ),
        },
      ]) ||
      [],

    comments:
      notification?.comments?.map((comment: IComment) => ({
        ...comment,
        isEditable: comment?.user?.email === currentUser.email,
        isDeletable: comment?.user?.email === currentUser.email,
      })) || [],

    auditLogs:
      notification?.auditLogs?.map((auditLog: any) => ({
        dateTime: formatDateTime(auditLog.created_at) || "-",
        performedBy: `${auditLog.user?.first_name} ${auditLog.user?.last_name}`,
        action: getAuditLogActionText(auditLog) || "-",
      })) || [],
  };
};

export const adtCodes = [
  { label: "Admission", value: "A01" },
  { label: "Transfer", value: "A02" },
  { label: "Emergency Discharge", value: "A03E" },
  { label: "Planned Discharge", value: "A03P" },
  { label: "Discharge", value: "A03" },
  { label: "Leave of Absence", value: "A06" },
  { label: "Change of Attending Physician", value: "A07" },
  { label: "Referral", value: "A08" },
  { label: "Change of Ward", value: "A09" },
  { label: "Return from Leave of Absence", value: "A05" },
  { label: "Readmission", value: "A01R" },
  { label: "Transfer to Another Facility", value: "A02F" },
  { label: "Transfer Within Facility", value: "A02W" },
  { label: "Death", value: "A03D" },
  { label: "Discharge to Home", value: "A03H" },
  { label: "Transfer to Hospice", value: "A02H" },
  { label: "Transfer to Rehabilitation", value: "A02R" },
  { label: "Discharge to Another Facility", value: "A03F" },
  { label: "Admission for Observation", value: "A01O" },
  { label: "Patient Expired", value: "A03X" },
  { label: "Transfer to ICU", value: "A02I" },
  { label: "Transfer to SNF (Skilled Nursing Facility)", value: "A02S" },
  { label: "Admission from Outpatient", value: "A01O" },
  { label: "Leave of Absence (Medical)", value: "A06M" },
  { label: "Leave of Absence (Personal)", value: "A06P" },
  { label: "Admission for Surgery", value: "A01S" },
  { label: "Discharge Against Medical Advice", value: "A03AMA" },
  { label: "Change of Room", value: "A09R" },
  { label: "Change of Hospital Service", value: "A09S" },
  { label: "Admission from ED", value: "A01ED" },
  { label: "Transfer to Outpatient", value: "A02O" },
  { label: "Discharge to Family Care", value: "A03FC" },
  { label: "Discharge to Skilled Nursing Facility", value: "A03SN" },
  { label: "Discharge to Home Health Care", value: "A03HH" },
  { label: "Discharge to Palliative Care", value: "A03PC" },
  { label: "Admission to Research", value: "A01R" },
  { label: "Temporary Leave", value: "A06T" },
  { label: "Observation Discharge", value: "A03O" },
  { label: "Transfer to Dialysis", value: "A02D" },
  { label: "Elective Admission", value: "A01E" },
  { label: "Involuntary Admission", value: "A01I" },
  { label: "Admission from Home", value: "A01H" },
  { label: "Admission for Medical Assessment", value: "A01MA" },
  { label: "Outpatient Procedure Admission", value: "A01OP" },
  { label: "Transfer to Psychiatric Unit", value: "A02P" },
  { label: "Discharge to Long-Term Care", value: "A03LT" },
  { label: "Transfer to Adult Day Care", value: "A02AD" },
  { label: "Discharge to Community Services", value: "A03CS" },
  { label: "Transfer to Home Care", value: "A02HC" },
  { label: "Discharge to Rehabilitation Facility", value: "A03RF" },
  { label: "Transfer to Specialty Care", value: "A02SC" },
  { label: "Admission for Diagnostic Testing", value: "A01DT" },
  { label: "Transfer to Observation Unit", value: "A02OU" },
  { label: "Discharge to Adult Foster Care", value: "A03AF" },
  { label: "Discharge for Further Treatment", value: "A03FT" },
  { label: "Discharge for Home Modifications", value: "A03HM" },
  { label: "Discharge to Assisted Living", value: "A03AL" },
  { label: "Discharge to Family Member", value: "A03FM" },
  { label: "Admission for Substance Abuse Treatment", value: "A01SA" },
  { label: "Transfer to Psychiatric Hospital", value: "A02PH" },
  { label: "Admission for Pain Management", value: "A01PM" },
  { label: "Discharge to Home with Home Health", value: "A03HH" },
  { label: "Discharge for Community Resources", value: "A03CR" },
  { label: "Transfer to Pediatric Unit", value: "A02PU" },
  { label: "Transfer to Geriatric Unit", value: "A02GU" },
  { label: "Emergency Admission", value: "A01EA" },
  { label: "Voluntary Admission", value: "A01VA" },
];

export function getADTCodeLabel(code: string) {
  return adtCodes.find((adtCode) => adtCode.value === code)?.label || code;
}
